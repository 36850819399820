const discountType = {
  PERCENTAGE: "percentage",
  AMOUNT: "amount",
}

const reportType = {
  Fabric: {
    FABRIC_DELIVERY: "Fabric delivery summary",
    url: "fabric-delivery-summary",
  },
  Purchase: {
    PURCHASE_SUMMARY: "Purchase summary",
    url: "purchase-summary",
  },
}

export { discountType, reportType }
