import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import { SaveToast } from "components/Common/SaveToast"
import Select from "react-select"
import {
  createPurchaseEntry,
  fetchJobberDetail,
  fetchManufacturerDetail,
  fetchProductDetails,
  getPurchaseEntryEdit,
  getSubCompanyDetails,
  updatePurchaseEntry,
} from "helpers/backendHelpers/purchase_entry"
import DatePicker from "components/Common/DatePicker"
import { handleValidation, InputField } from "components/Common/InputField"
import { fetchUnit } from "helpers/backendHelpers/product"
import ProductAttribute from "./ProductAttribute"
import { discountType } from "util/enum"
import SubmitLoader from "common/data/submitLoader"
import ViewSummary from "./ViewSummary"

const AddPurchaseEntry = props => {
  // fetch params from URL
  const { id, type } = useParams()

  const purchaseItems = {
    productId: "",
    productName: "",
    hsnCode: "",
    unit: "",
    discountType: discountType.PERCENTAGE,
    purchaseItemOperation: "add",
    quantity: "",
    taxAmount: "",
    totalAmount: "",
    price: "",
    taxableAmount: "",
    discountPercentage: "",
    discountAmount: "",
    taxPercentage: "",
    purchaseEntryVariants: [],
  }

  const [form, setForm] = useState({
    manufactureId: "",
    jobberId: "",
    subCompanyId: "",
    purchaseInvoiceNo: "",
    dispatchDate: "",
    purchaseEntryDate: "",
    deliveryManName: "",
    discountAmount: 0,
    totalAmount: 0,
    taxableAmount: 0,
    taxAmount: 0,
    CGST: "",
    grandTotal: 0,
    SGST: "",
    IGST: "",
    deliveryManContact: "",
    expectedDeliveryDate: "",
    dispatchDate: "",
    purchaseItems: [purchaseItems],
  })
  const [loading, setLoading] = useState(false)
  const [deleteOperation, setDeleteOperation] = useState({
    purchaseItem: [],
    purchaseEntryVariants: [],
  })

  useEffect(() => {
    document?.getElementById("purchase entry")?.classList?.add("mm-active")
    if (id) {
      fetchPurchaseEntry(id)
    }
  }, [id, type])

  console.log("deleteOperation", deleteOperation)

  // get purchase entry data
  const fetchPurchaseEntry = async id => {
    try {
      const response = await getPurchaseEntryEdit(id)
      let result = response.payload
      if (type !== "view") {
        const addOperationInPurchaseItem = result?.purchaseItems.map(item => ({
          ...item,
          purchaseItemOperation: "update",
          purchaseEntryVariants: item.purchaseEntryVariants.map(variant => ({
            ...variant,
            purchaseVariantOperation: "update",
          })),
        }))
        result["purchaseItems"] = addOperationInPurchaseItem
        result["jobberId"] = result?.jobberId?._id
        result["manufactureId"] = result?.manufactureId?._id
        result["subCompanyId"] = result?.subCompanyId?._id
      } else {
        setForm(result)
      }

      validation.setValues(result)
    } catch (error) {
      console.log(error)
    }
  }

  const [details, setDetails] = useState({
    subCompany: [],
    manufacturers: [],
    jobbers: [],
    products: [],
    units: [],
  })

  const fetchDataAndSetDetails = async (fetchFunction, labelKey, valueKey) => {
    try {
      const response = await fetchFunction()
      const result = response?.payload?.data || []

      // Mapping data to label and value
      const resultArray = result.map(item => ({
        label: item[labelKey],
        value: item[valueKey],
        ...item,
      }))

      return resultArray
    } catch (error) {
      return []
    }
  }

  const fetchAndSetDetails = async (
    fetchFunction,
    labelKey,
    valueKey,
    stateKey
  ) => {
    const data = await fetchDataAndSetDetails(fetchFunction, labelKey, valueKey)
    setDetails(prevState => ({
      ...prevState,
      [stateKey]: data,
    }))
  }

  const fetchAllDetails = async () => {
    await Promise.all([
      fetchAndSetDetails(getSubCompanyDetails, "name", "_id", "subCompany"),
      fetchAndSetDetails(
        fetchManufacturerDetail,
        "partyName",
        "_id",
        "manufacturers"
      ),
      fetchAndSetDetails(fetchJobberDetail, "partyName", "_id", "jobbers"),
      fetchAndSetDetails(fetchProductDetails, "productName", "_id", "products"),
    ])
  }

  useEffect(() => {
    if (type !== "view") {
      fetchAllDetails()
    }
  }, [])

  useEffect(() => {
    if (type !== "view") {
      getUnit()
    }
  }, [])

  const getUnit = async () => {
    try {
      const response = await fetchUnit()
      setDetails(prevState => ({
        ...prevState,
        units: response.payload,
      }))
    } catch (error) {}
  }

  // handle create purchase entry
  const handleCreatePurchase = async data => {
    try {
      setLoading(true)
      const response = await createPurchaseEntry(data)
      const message = response.message
      SaveToast({ message, type: "success" })
      props.history.push(`/purchase-entry`)
      setLoading(false)
    } catch (error) {
      SaveToast({ message, type: "error" })
    }
  }

  // handle update jobber
  const handleUpdatePurchase = async (id, data) => {
    // concat delete purchase variant and purchaseItem if exist on state and generate new object
    data = {
      ...data,
      purchaseItems:
        deleteOperation.purchaseItem.length > 0
          ? data.purchaseItems.concat(
              ...deleteOperation.purchaseItem.filter(
                p => p.purchaseEntryId === data._id
              )
            )
          : data.purchaseItems.map(val => {
              return {
                ...val,
                purchaseEntryVariants: val.purchaseEntryVariants.concat(
                  ...(deleteOperation.purchaseEntryVariants.length > 0
                    ? deleteOperation.purchaseEntryVariants.filter(
                        p => p.purchaseEntryItemId === val._id
                      )
                    : val.purchaseEntryVariants)
                ),
              }
            }),
    }
    try {
      setLoading(true)
      await updatePurchaseEntry(id, data)
      props.history.push(`/purchase-entry`)
      setLoading(false)
    } catch (error) {
      console.log("error", error)
      setLoading(false)
      const message = error?.response?.data?.message
      SaveToast({ message, type: "error" })
    }
  }

  // validation && onSubmit Event
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: form,
    validationSchema: Yup.object({
      purchaseInvoiceNo: Yup.string().required("Please Enter invoice no."),
      dispatchDate: Yup.string().required("Please Enter dispatch date"),
      subCompanyId: Yup.string().required("Please select one company"),
      manufactureId: Yup.string().required("Please select one manufacturer"),
      jobberId: Yup.string().required("Please select one jobber"),
      purchaseEntryDate: Yup.string().required("Please Enter date"),
      expectedDeliveryDate: Yup.string().required(
        "Please Enter expected delivery date"
      ),
      purchaseItems: Yup.array().of(
        Yup.object().shape({
          productId: Yup.string().required("Please select product"),
          quantity: Yup.number()
            .required("Please Enter quantity")
            .positive("Quantity should be positive"),
          price: Yup.number()
            .required("Please Enter price")
            .positive("Price should be positive"),
          unit: Yup.string().required("Please select unit"),
          discountPercentage: Yup.number()
            .required("Please Enter discount")
            .moreThan(-1, "Discount should be positive"),
          taxPercentage: Yup.number()
            .required("Please Enter tax")
            .positive("Tax should be positive"),
          hsnCode: Yup.string().required("Please Enter HSN code"),
          // purchaseEntryVariants: Yup.array().of(
          //   Yup.object().shape({
          //     unit: Yup.string().required("Please select unit"),
          //   })
          // ),
        })
      ),
    }),

    onSubmit: values => {
      // const res = mergedArray(values, deleteOperation.purchaseEntryVariants)
      // console.log("res", res)
      values.deliveryManContact = values?.deliveryManContact?.toString()
      if (type === "edit") {
        return handleUpdatePurchase(id, values)
      } else return handleCreatePurchase(values)
    },
  })

  console.log("validation.errors", validation.errors)

  const handleChange = (name, value) => {
    validation.setFieldValue(name, value)
  }

  return (
    <React.Fragment>
      {type === "view" ? (
        <ViewSummary summary={form} />
      ) : (
        <div className="page-content">
          {loading && <SubmitLoader />}
          <div className="container-fluid">
            <Breadcrumb
              title="Purchase Entry"
              breadcrumbItem="Purchase Entry"
            />
          </div>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {type == "edit"
                      ? "Update Purchase Entry"
                      : "Create Purchase Entry"}
                  </CardTitle>

                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="border-bottom py-2">
                      <Row>
                        <Col md={1}>
                          <Label>
                            Invoice No.
                            <span className="text-danger">*</span>
                          </Label>
                          <InputField
                            type="text"
                            name="purchaseInvoiceNo"
                            placeholder="invoice"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            value={validation.values.purchaseInvoiceNo || ""}
                          />
                          {handleValidation(validation, "purchaseInvoiceNo")}
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>
                              Company
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={details.subCompany.find(
                                val =>
                                  val._id === validation.values.subCompanyId
                              )}
                              onChange={e => {
                                console.log("e", e)
                                handleChange("subCompanyId", e._id)
                              }}
                              name="subCompanyId"
                              onBlur={validation.handleBlur}
                              options={details.subCompany}
                              classNamePrefix="select2-selection"
                            />
                            {handleValidation(validation, "subCompanyId")}
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <Label>
                              Manufacturer
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={details.manufacturers.find(
                                val =>
                                  val._id === validation.values.manufactureId
                              )}
                              onChange={e => {
                                handleChange("manufactureId", e._id)
                              }}
                              name="manufactureId"
                              onBlur={validation.handleBlur}
                              options={details.manufacturers}
                              classNamePrefix="select2-selection"
                            />
                            {handleValidation(validation, "manufactureId")}
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <Label>
                              Jobber<span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={details.jobbers.find(
                                val => val._id === validation.values.jobberId
                              )}
                              onChange={e => {
                                handleChange("jobberId", e._id)
                              }}
                              options={details.jobbers}
                              classNamePrefix="select2-selection"
                            />
                            {handleValidation(validation, "jobberId")}
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">
                              Purchase Entry Date
                              <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                              value={Date.parse(
                                validation.values.purchaseEntryDate || ""
                              )}
                              // onBlur={validation.handleBlur}
                              onChange={dates => {
                                validation.setFieldValue(
                                  "purchaseEntryDate",
                                  dates[0].toISOString()
                                )
                              }}
                            />
                            {handleValidation(validation, "purchaseEntryDate")}
                          </div>
                        </Col>
                        {/* <Col md={4}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-InputCity">
                            Purchase Entry Code
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="jobberCode"
                            placeholder="Enter party code"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.jobberCode || ""}
                            invalid={
                              validation.touched.jobberCode &&
                                validation.errors.jobberCode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.jobberCode &&
                            validation.errors.jobberCode ? (
                            <FormFeedback type="invalid">
                              {validation.errors.jobberCode}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}
                      </Row>
                    </div>
                    <div className="py-2 border-bottom">
                      <CardTitle className="my-4">Delivery Details</CardTitle>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              name="deliveryManName"
                              placeholder="Enter name"
                              onChange={validation.handleChange}
                              value={validation?.values?.deliveryManName}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputZip">
                              Mobile Number
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              name="deliveryManContact"
                              placeholder="Enter mobile number"
                              onChange={validation.handleChange}
                              value={validation.values.deliveryManContact || ""}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="border-bottom py-3">
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">
                              Expected Delivery
                              <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                              name="expectedDeliveryDate"
                              value={Date.parse(
                                validation.values.expectedDeliveryDate || ""
                              )}
                              onBlur={validation.handleBlur}
                              // onBlur={validation.handleBlur}
                              onChange={dates => {
                                validation.setFieldValue(
                                  "expectedDeliveryDate",
                                  dates[0].toISOString()
                                )
                              }}
                            />

                            {handleValidation(
                              validation,
                              "expectedDeliveryDate"
                            )}
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-InputCity">
                              Dispatch Date
                              <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                              name="dispatchDate"
                              onBlur={validation.handleBlur}
                              value={Date.parse(
                                validation.values.dispatchDate || ""
                              )}
                              // onBlur={validation.handleBlur}
                              onChange={dates => {
                                validation.setFieldValue(
                                  "dispatchDate",
                                  dates[0].toISOString()
                                )
                              }}
                            />
                            {handleValidation(validation, "dispatchDate")}
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/* create product and variants */}
                    <ProductAttribute
                      setDeleteOperation={setDeleteOperation}
                      deleteOperation={deleteOperation}
                      values={validation.values}
                      allData={details}
                      purchaseItem={purchaseItems}
                      setValues={validation.setValues}
                      type={type}
                      validation={validation}
                    />

                    <div className="d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-danger w-md my-4 mx-2"
                        onClick={() => props.history.push("/purchase-entry")}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn w-md my-4 btn-success"
                      >
                        {type == "edit" ? "Update" : "Save"}
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  )
}

export default AddPurchaseEntry
