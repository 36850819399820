import Breadcrumb from "components/Common/Breadcrumb"
import { SaveToast } from "components/Common/SaveToast"
import TableContainer from "components/Common/TableContainer"
import { GSTValue, SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import {
  deleteProductRecord,
  fetchAllProducts,
} from "helpers/backendHelpers/product"
import ConfirmationModal from "components/Common/ConfirmationModal"

const ProductList = props => {
  //meta title
  document.title = "Products | VH BROTHER"

  const [allproduct, setAllProduct] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteId, setDeleteId] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  useEffect(() => {
    fetchAllProduct()
  }, [])

  // fetch all products
  const fetchAllProduct = async () => {
    setLoading(true)
    try {
      const response = await fetchAllProducts()
      setAllProduct(response.payload.data)
      setLoading(false)
    } catch (error) {
      SaveToast({ type: "error", message: error?.response?.data?.message })
      setLoading(false)
    }
  }

  const deleteProduct = async () => {
    setDeleteLoading(true)
    try {
      const response = await deleteProductRecord(deleteId)
      SaveToast({ type: "success", message: response.message })
      fetchAllProduct()
      setDeleteModal(false)
      setDeleteLoading(false)
    } catch (error) {
      setDeleteLoading(false)
      return error
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "productImage",
        filterable: true,
        Cell: cellProps => {
          return (
            <React.Fragment>
              {
                <div
                  className="dropzone-previews mt-3 text-center"
                  id="file-previews"
                >
                  <div>
                    <a
                      href={cellProps.value}
                      target="_blank"
                      style={{ color: "#0D6EFD" }}
                    >
                      <i
                        className="bx bxs-image font-size-24"
                        id={cellProps.value ? "viewtooltip" : "notooltip"}
                      />
                    </a>
                    <UncontrolledTooltip
                      placement="top"
                      target={cellProps.value ? "viewtooltip" : "notooltip"}
                    >
                      {cellProps.value ? "View" : "No"} Image
                    </UncontrolledTooltip>
                  </div>
                </div>
              }
            </React.Fragment>
          )
        },
      },
      {
        Header: "Name",
        accessor: "productName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Product Code",
        accessor: "productCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "HSN Code",
        accessor: "hsnCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Default GST%",
        accessor: "gstPercentage",
        filterable: true,
        Cell: cellProps => {
          return <GSTValue {...cellProps} />
        },
      },
      {
        Header: "unit",
        accessor: "unit",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const editId = cellProps.row.original._id
                  props.history.push("/product/edit/" + editId)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const deleteId = cellProps.row.original._id
                  setDeleteId(deleteId)
                  setDeleteModal(true)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="product" breadcrumbItem="product" />

        {/* Delete Modal */}
        <ConfirmationModal
          show={deleteModal}
          onClick={deleteProduct}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleteLoading}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={allproduct}
                  isSearchable={true}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  addButtonLabel={"Add Product"}
                  handleAddButtonClick={() =>
                    props.history.push("/product/add")
                  }
                  dataFetchLoading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ProductList
