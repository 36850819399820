export const createStringQueryParams = (value) => {
    let allString = '';
    //* combine string which i am using the query params
    for (let key in value) {
        if (value[key] != '') {
            let string = '&' + key + '=' + value[key];
            allString += string;
        }
    }
    return allString;
};