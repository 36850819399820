import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"
import { API_URL } from "./url_helper"
import { SaveToast } from "components/Common/SaveToast"
import { getCompanyDetails } from "./authHelper"

//pass new generated access token here
const token = accessToken

export const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  data["companyId"] = getCompanyDetails()[0]?._id
  return await axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(error => {
      SaveToast({ type: "error", message: error.response.data.message })
    })
}

export async function put(url, data, config = {}) {
  data["companyId"] = getCompanyDetails()[0]?._id
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(error => {
      SaveToast({ type: "error", message: error.response.data.message })
    })
}
