import React from "react"
import Select from "react-select"

function SingleSelect({
  options,
  value,
  onChange,
  isClear = true,
  onBlur = () => null,
}) {
  return (
    <Select
      placeholder="select..."
      value={value}
      isClearable={isClear}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      classNamePrefix="select2-selection"
    />
  )
}

export default SingleSelect
