import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Col,
  Row,
  CardTitle,
  Label,
  Modal,
  ModalBody,
  NavItem,
  NavLink,
  TabPane,
  Badge,
  Input,
} from "reactstrap"
import SingleSelect from "components/Common/SingleSelect"
import {
  handleValidation,
  InputField,
  InputType,
} from "components/Common/InputField"
import classnames from "classnames"
import { SaveToast } from "components/Common/SaveToast"
import {
  fetchProductAttribute,
  fetchProductVariant,
  generateCombination,
} from "helpers/backendHelpers/purchase_entry"
import deleteIcon from "assets/images/delete.png"
import { discountType } from "util/enum"
import SubmitLoader from "common/data/submitLoader"
import ConfirmationModal from "components/Common/ConfirmationModal"
import { set } from "lodash"

const ProductAttribute = ({
  values,
  validation,
  deleteOperation,
  allData,
  setDeleteOperation,
  setValues,
  type,
  purchaseItem,
}) => {
  const [variantModel, setVariantModel] = useState(false)
  const [activeTab, setActiveTab] = useState("3")
  const [subActiveTab, setSubActiveTab] = useState({
    tab: "0",
    id: "",
  })
  const [productIndex, setProductIndex] = useState(0)
  const [productDetails, setProductDetails] = useState({
    attribute: [],
    variant: [],
    filteredVariant: [],
    unit: [],
  })

  const [loading, setLoading] = useState(false)
  const [isVariantSubmit, setIsVariantSubmit] = useState(false)
  const [variantArray, setVariantArray] = useState([])
  const [isConfirmation, setIsConfirmation] = useState(false)
  const [isAttributeSubmit, setIsAttributeSubmit] = useState(false)

  //* toggle tab basic form and combination tab
  const toggleTab = tab => {
    if (
      tab === "4" &&
      values.purchaseItems[productIndex].purchaseEntryVariants.length == 0
    ) {
      return SaveToast({ type: "error", message: "Please select Attribute" })
    }

    // if is update page and variant is available then don't do the change tab
    if (type === "edit") {
      if (
        tab === "3" &&
        values.purchaseItems[productIndex].purchaseEntryVariants.length > 0
      ) {
        return SaveToast({
          type: "error",
          message: "please remove variant after generate new attribute",
        })
      }
    }

    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  //* toggle tab basic form and combination tab
  const subToggleTab = (tab, id) => {
    if (activeTab !== tab) {
      setSubActiveTab({ ...subActiveTab, tab: tab.toString(), id: id })
    }
  }

  const fetchAttributeVariant = selectedId => {
    const filtered = productDetails.variant.filter(
      item => item.attributeId == selectedId
    )
    setProductDetails({ ...productDetails, filteredVariant: filtered })
  }

  //* fetch all records
  const fetchAllRecords = async () => {
    //* fetch product attribute
    try {
      const attributeResponse = await fetchProductAttribute()
      const variation = await fetchProductVariant()

      //* set data to state
      setProductDetails({
        attribute: attributeResponse.payload.data,
        variant: variation.payload.data,
        filteredVariant: variation.payload.data.filter(
          variant => variant._id == subActiveTab.id
        ),
      })
      if (subActiveTab.id) {
        fetchAttributeVariant(subActiveTab.id)
      }
    } catch (error) {
      SaveToast({ type: "error", message: error.response.data.message })
    }
  }

  // fetch all records
  useEffect(() => {
    fetchAllRecords()
  }, [])

  //* add product to list
  const addProductClick = () => {
    // set Form value
    const formValues = { ...values }
    formValues.purchaseItems.push(purchaseItem)
    setValues(formValues)
  }

  // * handle add variants
  const addVariant = index => {
    console.log("index", index)
    setProductIndex(index)
    toggle(index)

    //* fetch first record's id and set in state( variant )
    fetchAttributeVariant(productDetails?.attribute[0]?._id)
  }

  // * handle add variant
  const variantOnChange = variantId => {
    if (variantArray.includes(variantId)) {
      // if variant already exists and false to checkbox then remove it from the array
      setVariantArray(variantArray.filter(id => id !== variantId))
    } else {
      // if variant does not exist and true to checkbox then add it to the array
      setVariantArray([...variantArray, variantId])
    }
  }

  // * handle add variant
  const submitVariant = async () => {
    setLoading(true)
    const formData = {
      productId: values.purchaseItems[productIndex].productId,
      variantIds: variantArray,
    }

    if (variantArray.length == 0) {
      SaveToast({ type: "error", message: "please create attribute" })
    } else {
      try {
        const response = await generateCombination(formData)
        const formValues = { ...values }
        formValues.purchaseItems[productIndex].purchaseEntryVariants =
          response?.payload
        setValues(formValues)

        SaveToast({ type: "success", message: response.message })
        setActiveTab("4")
        setLoading(false)
      } catch (error) {
        SaveToast({ type: "error", message: error.response.data.message })
        setLoading(false)
      }
    }
  }

  // onchange event for product details
  const handleProductDetailsChange = e => {
    const { value, name } = e.target
    const index = name.split(".")[1]
    const key = name.split(".")[2]

    const formData = { ...values }
    formData.purchaseItems[index][key] = value

    // calculation of gst and discount
    if (key === "hsnCode" || key === "unit") {
      setValues(formData)
    } else {
      calculation(formData, index)
    }
  }

  // calculation of gst and discount
  const calculation = (formValues, index) => {
    // multiplication of quantity and price and set in total amount or multiply by gst amount
    const qty = Number(formValues.purchaseItems[index]?.quantity)
    const price = Number(formValues.purchaseItems[index]?.price)
    let totalAmount = Number(formValues.purchaseItems[index].totalAmount)
    // discount amount
    const discountAmount = Number(
      formValues.purchaseItems[index].discountAmount
    )
    // discount percentage
    const discountPercentage = Number(
      formValues.purchaseItems[index].discountPercentage
    )
    // tax percentage
    const taxPercentage = Number(formValues.purchaseItems[index].taxPercentage)
    // discount type
    const disType = formValues.purchaseItems[index].discountType

    const totalQtyPrice = qty * price

    // calculate discount amount from discount percentage
    const disAmount = (totalQtyPrice * discountPercentage) / 100
    const discountPer = (discountAmount / totalQtyPrice) * 100

    let totalAmtDiscount

    // get discount percentage from discount amount type wise

    if (disType === discountType.AMOUNT) {
      formValues.purchaseItems[index].discountPercentage =
        discountPer.toFixed(2)

      // if type is amount then set discount amount
      totalAmtDiscount = totalQtyPrice - discountAmount
      formValues.purchaseItems[index].taxableAmount =
        totalQtyPrice - discountAmount
    } else {
      formValues.purchaseItems[index].taxableAmount = totalQtyPrice - disAmount
      // if type is percentage then set disAmount
      totalAmtDiscount = totalQtyPrice - disAmount
      formValues.purchaseItems[index].discountAmount = disAmount
    }

    // calculation of tax amount with gst
    const gstAmount = (totalAmtDiscount * taxPercentage) / 100
    formValues.purchaseItems[index].taxAmount = gstAmount

    // calculate of total amount with discount and gst
    if (disType === discountType.AMOUNT) {
      totalAmount = totalQtyPrice - discountAmount + gstAmount
    } else {
      totalAmount = totalQtyPrice - disAmount + gstAmount
    }
    // if discount amount is greater than total amount then show error
    if (discountAmount > totalQtyPrice) {
      formValues.purchaseItems[index].discountAmount = 0
      formValues.purchaseItems[index].discountPercentage = 0
      return SaveToast({
        type: "error",
        message: "discount amount should be lesser than amount",
      })
    }

    // if discount percentage is greater than 100 then show error
    if (discountPercentage > 100) {
      formValues.purchaseItems[index].discountPercentage = 0
      formValues.purchaseItems[index].discountAmount = 0
      return SaveToast({
        type: "error",
        message: "discount percentage should be lesser than 100",
      })
    }
    formValues.purchaseItems[index].totalAmount = totalAmount.toFixed(2)
    allTotalAmount(formValues)
  }

  // calculate all total amount , discount and tax amount
  const allTotalAmount = formValues => {
    let totalAmount = 0
    let discountAmount = 0
    let taxableAmount = 0
    let gstAmount = 0

    for (let i = 0; i < formValues.purchaseItems.length; i++) {
      const data = formValues.purchaseItems[i]
      // total amount qty * price
      totalAmount += Number(data.quantity) * Number(data.price)
      // total discount amount
      discountAmount += Number(data.discountAmount)
      // total taxable amount
      taxableAmount += Number(data.taxableAmount)

      // calculate taxable amount
      gstAmount += Number(data.taxAmount)
    }

    // set all total amount in form values
    formValues.totalAmount = totalAmount.toFixed(2)
    formValues.discountAmount = discountAmount.toFixed(2)
    formValues.taxableAmount = taxableAmount.toFixed(2)
    formValues.taxAmount = gstAmount.toFixed(2)
    formValues.grandTotal = (taxableAmount + gstAmount).toFixed(2)

    setValues(formValues)
  }

  // onchange event for variant details
  const onChangeEventOfVariants = (e, index, name) => {
    const { value } = e.target
    const formValues = { ...values }
    formValues.purchaseItems[productIndex].purchaseEntryVariants[index][name] =
      value
    setValues(formValues)
  }

  console.log("values", values)

  // toggle variant model
  const toggle = index => {
    const formData = { ...values }
    console.log("productIndex", productIndex)
    if (variantModel) {
      // if variant submit button is not clicked then show the confirmation modal
      if (!isAttributeSubmit && !isVariantSubmit && type !== "edit") {
        if (
          formData.purchaseItems[productIndex].purchaseEntryVariants.length >
            0 ||
          variantArray.length > 0
        ) {
          setIsConfirmation(true)
          return
        }
      }
      setVariantModel(false)
    } else {
      setVariantModel(true)

      if (
        type === "edit" &&
        formData.purchaseItems[index].purchaseEntryVariants.length > 0
      ) {
        setActiveTab("4")
      } else {
        setActiveTab("3")
      }

      // id modal is open and variant is not empty then set the variant in the form
      if (!isVariantSubmit && type === "add") {
        if (index) {
          formData.purchaseItems[index].purchaseEntryVariants = []
          setValues(formData)
        }

        // remove all data from the attributes
        setSubActiveTab({ tab: "0", id: "" })
        // setActiveTab("3")
        setVariantArray([])
      }
    }
    setIsAttributeSubmit(false)
  }

  // handle remove variants on click
  const handleRemoveVariants = index => {
    let formData = { ...values }
    const variant = formData.purchaseItems[productIndex].purchaseEntryVariants

    // if id is available then set the operation to delete other wise splice on array
    if (type === "edit" && variant.length > 0 && variant[index]._id) {
      // add key to the object if delete from edit page
      formData.purchaseItems[productIndex].purchaseEntryVariants[
        index
      ].purchaseVariantOperation = "delete"

      console.log(
        "formData",
        formData.purchaseItems[productIndex].purchaseEntryVariants[index]
      )
      setDeleteOperation({
        ...deleteOperation,
        purchaseEntryVariants: [
          ...deleteOperation.purchaseEntryVariants,
          formData.purchaseItems[productIndex].purchaseEntryVariants[index],
        ],
      })
    }

    variant.splice(index, 1)

    // if all variants are removed then change the tab
    if (variant.length == 0) {
      setVariantArray([])
      setActiveTab("3")
    }
    setValues(formData)
  }

  // handle delete product
  const handleDeleteProduct = index => {
    const formData = { ...values }

    // if id is available then set the operation to delete other wise splice on array
    if (formData.purchaseItems[index]._id) {
      formData.purchaseItems[index].purchaseItemOperation = "delete"
      setDeleteOperation({
        ...deleteOperation,
        purchaseItem: [
          ...deleteOperation.purchaseItem,
          formData.purchaseItems[index],
        ],
      })
    }
    formData.purchaseItems.splice(index, 1)
    allTotalAmount(formData)
  }

  return (
    <React.Fragment>
      <ConfirmationModal
        onClick={() => {
          setVariantModel(false)
          setIsConfirmation(false)
        }}
        onCloseClick={() => setIsConfirmation(false)}
        show={isConfirmation}
        loading={loading}
      />
      <div className="text-end mt-4">
        <button
          className="btn btn-primary-blue text-white font-size-10 fw-medium"
          type="button"
          onClick={addProductClick}
        >
          <span className="px-1">
            <i className="bx bx-plus-circle" />
          </span>
          <span>Add Product</span>
        </button>
      </div>
      <CardTitle className="mb-2 border-top-1 border-primary">
        Product Details
      </CardTitle>
      {values.purchaseItems?.map((item, index) => (
        <div className="border rounded-3 p-3 mt-3" key={index}>
          <div className="text-end">
            {index !== 0 && (
              <img
                onClick={() => {
                  handleDeleteProduct(index)
                }}
                className="cursor-pointer"
                src={deleteIcon}
                alt=""
              />
            )}
          </div>
          <Row className="font-size-12 align-items-center mt-2">
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputCity">
                  Product
                  <span className="text-danger">*</span>
                </Label>{" "}
                <SingleSelect
                  value={allData?.products.find(
                    val => val._id === item.productId
                  )}
                  onBlur={validation.handleBlur}
                  isClear={false}
                  onChange={(e, { action }) => {
                    const formData = { ...values }
                    formData.purchaseItems[index].productId = e.value
                    setValues(formData)
                  }}
                  // onChange={(e, { action }) => { if (action == "clear") { handleRemoveChange(item); } else { handleProductDetailsChange(e, item) } }}
                  options={allData?.products}
                />
                {handleValidation(
                  validation,
                  "productId",
                  "purchaseItems",
                  index
                )}
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputZip">
                  HSN Code
                  <span className="text-danger">*</span>
                </Label>
                <InputField
                  type="number"
                  name={`purchaseItems.${index}.hsnCode`}
                  placeholder="Enter HSN Code"
                  onChange={e => handleProductDetailsChange(e)}
                  validation={validation.errors}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched?.challans?.[index]?.["hsnCode"] &&
                    validation?.errors?.challans?.[index]?.["hsnCode"]
                  }
                  value={item.hsnCode}
                />
                {handleValidation(
                  validation,
                  "hsnCode",
                  "purchaseItems",
                  index
                )}
              </div>
            </Col>
            <Col md={4} className="add-variant-box">
              <div className="bg-light-orange p-2 rounded ">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="fw-medium font-size-12">
                    Product Variants<span className="text-danger">*</span>
                  </div>
                  <button
                    type="button"
                    className="btn bg-white text-primary font-size-10 fw-medium"
                    onClick={e => {
                      if (item.productId === "") {
                        return SaveToast({
                          type: "error",
                          message: "Please select product",
                        })
                      }
                      console.log("index", index)
                      addVariant(index)
                    }}
                  >
                    <span className="px-1">
                      <i
                        className={
                          item?.purchaseEntryVariants?.length > 0
                            ? "mdi mdi-eye"
                            : "bx bx-plus-circle"
                        }
                      />
                    </span>
                    <span>
                      {item?.purchaseEntryVariants?.length > 0
                        ? "View Variants"
                        : "Add Variants"}
                    </span>
                  </button>
                </div>
                {item?.purchaseEntryVariants?.length > 0 ? (
                  <div className="details">
                    <div className="qty-variants">
                      <span>Total Variants</span>
                      <span>Total Quantity</span>
                    </div>
                    <div className="qty-variants">
                      <span>{item.purchaseEntryVariants.length}</span>
                      {/* count total qty */}
                      <span>
                        {item.purchaseEntryVariants.reduce((acc, curr) => {
                          return acc + parseInt(curr.quantity)
                        }, 0)}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className={isVariantSubmit ? "mt-2" : ""}>
            <Col xs={2}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputCity">
                  Unit
                  <span className="text-danger">*</span>
                </Label>
                <Input
                  type="select"
                  name={`purchaseItems.${index}.unit`}
                  placeholder="Unit"
                  invalid={
                    validation.touched?.purchaseItems?.[index]?.unit &&
                    validation?.errors?.purchaseItems?.[index]?.unit
                  }
                  onChange={e => handleProductDetailsChange(e)}
                  onBlur={validation.handleBlur}
                  value={item.unit}
                >
                  <option value="">Select Unit</option>
                  {allData?.units.map(val => (
                    <option value={val.name} key={val.name}>
                      {val.name}
                    </option>
                  ))}
                </Input>
                {handleValidation(validation, "unit", "purchaseItems", index)}
              </div>
            </Col>
            <Col xs={2}>
              <div className="mb-3">
                <Label htmlFor="formrow-InputZip">
                  Quantity
                  <span className="text-danger">*</span>
                </Label>
                <InputField
                  type="number"
                  name={`purchaseItems.${index}.quantity`}
                  invalid={
                    validation.touched?.purchaseItems?.[index]?.quantity &&
                    validation?.errors?.purchaseItems?.[index]?.quantity
                  }
                  placeholder="0"
                  onChange={e => handleProductDetailsChange(e)}
                  onBlur={validation.handleBlur}
                  value={item.quantity}
                />
                {handleValidation(
                  validation,
                  "quantity",
                  "purchaseItems",
                  index
                )}
              </div>
            </Col>

            <Col xs={2}>
              <div className="mb-2">
                <Label htmlFor="formrow-InputZip">
                  Rate
                  <span className="text-danger">*</span>
                </Label>
                <InputField
                  type="number"
                  name={`purchaseItems.${index}.price`}
                  placeholder="0"
                  invalid={
                    validation.touched?.purchaseItems?.[index]?.price &&
                    validation?.errors?.purchaseItems?.[index]?.price
                  }
                  onChange={e => handleProductDetailsChange(e)}
                  onBlur={validation.handleBlur}
                  value={item.price}
                />
                {handleValidation(validation, "price", "purchaseItems", index)}
              </div>
            </Col>

            <Col xs={2}>
              <Label htmlFor="formrow-InputZip">
                Discount
                <span className="text-danger">*</span>
              </Label>
              <div className="position-relative">
                <InputField
                  type="number"
                  invalid={
                    validation.touched?.purchaseItems?.[index]
                      ?.discountPercentage &&
                    validation?.errors?.purchaseItems?.[index]
                      ?.discountPercentage
                  }
                  placeholder={"0"}
                  name={`purchaseItems.${index}.${
                    item.discountType === discountType.PERCENTAGE
                      ? "discountPercentage"
                      : "discountAmount"
                  }`}
                  onChange={e => handleProductDetailsChange(e)}
                  onBlur={validation.handleBlur}
                  value={
                    item.discountType === discountType.PERCENTAGE
                      ? item.discountPercentage
                      : item.discountAmount
                  }
                />
                <InputType
                  item={item}
                  index={index}
                  name={`purchaseItems.${index}.discountType`}
                  onChange={e => handleProductDetailsChange(e)}
                />
                {item.discountType === discountType.PERCENTAGE &&
                item.discountAmount > 0
                  ? item.discountAmount
                  : item.discountPercentage > 0
                  ? `${item.discountPercentage}%`
                  : null}
                {handleValidation(
                  validation,
                  "discountPercentage",
                  "purchaseItems",
                  index
                )}
              </div>
            </Col>
            <Col xs={2}>
              <Label htmlFor="formrow-InputZip">
                GST(%)
                <span className="text-danger">*</span>
              </Label>
              <div className="position-relative">
                <InputField
                  type="number"
                  invalid={
                    validation.touched?.purchaseItems?.[index]?.taxPercentage &&
                    validation?.errors?.purchaseItems?.[index]?.taxPercentage
                  }
                  placeholder={"0"}
                  name={`purchaseItems.${index}.taxPercentage`}
                  onChange={e => handleProductDetailsChange(e)}
                  onBlur={validation.handleBlur}
                  value={item.taxPercentage}
                />
                {handleValidation(
                  validation,
                  "taxPercentage",
                  "purchaseItems",
                  index
                )}
              </div>
            </Col>
            <Col xs={2}>
              <div className="mb-2">
                <Label htmlFor="formrow-InputZip">Total Amount</Label>
                <InputField
                  placeholder="0"
                  disabled={true}
                  value={item.totalAmount}
                />
              </div>
            </Col>
          </Row>
        </div>
      ))}

      <Row className="purchase-summary">
        <div className="box">
          <h4>Summary</h4>
          <div>
            <div className="calculate">
              <span>Total Amount</span>
              <span>{values?.totalAmount}</span>
            </div>
            <div className="calculate">
              <span>Discount Amount</span>
              <span>{values?.discountAmount}</span>
            </div>
            {/* <div className="calculate">
                  <span>SGST</span>
                  <span>0.0</span>
                </div>
                <div className="calculate">
                  <span>CGST</span>
                  <span>0.0</span>
                </div>
                <div className="calculate">
                  <span>IGST</span>
                  <span>0.0</span>
                </div> */}
            <div className="calculate">
              <span>Taxable Amount</span>
              <span>{values.taxableAmount}</span>
            </div>
            <div className="calculate">
              <span>Gst Amount</span>
              <span>{values.taxAmount}</span>
            </div>
            <div className="calculate">
              <span>Final Amount</span>
              <span>{values?.grandTotal}</span>
            </div>
          </div>
        </div>
      </Row>

      {/* attribute and variants model */}
      <Modal isOpen={variantModel} toggle={toggle} centered={true}>
        <ModalBody className="py-3 px-5">
          {loading && <SubmitLoader />}

          <Row>
            <div className="d-flex justify-content-between align-items-center mb-3 px-0">
              <CardTitle className="mb-0">
                Choose Attributes & make varients
              </CardTitle>
              <div onClick={toggle} className="cursor-pointer">
                <i className="mdi mdi-close-circle-outline font-size-16 external-event" />
              </div>
            </div>
            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
              <Col lg={12}>
                <Row>
                  <Col md={6} className="p-0">
                    <NavItem className="fw-bold">
                      <NavLink
                        className={`text-center ${classnames({
                          active: activeTab === "3",
                        })}`}
                        onClick={() => {
                          toggleTab("3")
                        }}
                      >
                        Attributes
                      </NavLink>
                    </NavItem>
                  </Col>
                  <Col md={6} className="p-0">
                    <NavItem className="fw-bold">
                      <NavLink
                        className={`text-center ${classnames({
                          active: activeTab === "4",
                        })}`}
                        onClick={() => {
                          toggleTab("4")
                        }}
                      >
                        Variants
                      </NavLink>
                    </NavItem>
                  </Col>
                </Row>
              </Col>
            </ul>
            {activeTab === "3" && (
              <TabPane
                activetab={activeTab}
                tabId="3"
                id="processing"
                className="px-0"
              >
                <div>
                  <ul
                    className="nav nav-tabs nav-sub-tabs-custom my-4"
                    role="tablist"
                  >
                    {productDetails?.attribute?.map((attribute, index) => (
                      <NavItem className="fw-bold" key={index}>
                        <NavLink
                          className={classnames({
                            active: subActiveTab.tab.toString() == index,
                          })}
                          onClick={() => {
                            subToggleTab(index, attribute?._id)
                            fetchAttributeVariant(attribute?._id)
                          }}
                        >
                          {attribute.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </ul>
                  {subActiveTab.tab.toString() ? (
                    <TabPane
                      activetab={subActiveTab.tab}
                      tabId={subActiveTab.tab}
                      id="processing"
                    >
                      <div>
                        {productDetails?.filteredVariant.length > 0 ? (
                          <Row>
                            {productDetails?.filteredVariant &&
                              productDetails?.filteredVariant?.map(
                                (variant, index) => (
                                  <Col
                                    md={3}
                                    className="form-check form-check-warning mb-3"
                                    key={index}
                                  >
                                    <div
                                      className="form-check form-check-warning mb-3 cursor-pointer"
                                      key={variant._id}
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input cursor-pointer"
                                        id={`customCheckcolor${variant._id}`}
                                        onChange={e =>
                                          variantOnChange(variant._id)
                                        }
                                        // defaultChecked={type === "edit" ?  editVariants.find(val =>
                                        //   val.productVariantIds.includes(
                                        //     variant._id
                                        //   )
                                        // ) : variantArray.includes(variant._id)}
                                        defaultChecked={variantArray.includes(
                                          variant._id
                                        )}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`customCheckcolor${variant._id}`}
                                      >
                                        {variant.name}
                                      </label>
                                    </div>
                                  </Col>
                                )
                              )}
                          </Row>
                        ) : (
                          "No Variant"
                        )}
                      </div>
                    </TabPane>
                  ) : null}
                  <Row>
                    <Col>
                      <div className="text-center mt-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 btn-lg ms-2"
                          onClick={() => {
                            setIsAttributeSubmit(true)
                            submitVariant()
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
            {activeTab === "4" && (
              <TabPane
                activetab={activeTab}
                tabId="4"
                id="processing"
                className="px-0"
              >
                <div>
                  {values?.purchaseItems[
                    productIndex
                  ]?.purchaseEntryVariants?.map((variant, index) => (
                    <div className="my-3">
                      <div>
                        {variant.combination.split("-").map((val, index) => (
                          <Badge
                            key={index}
                            className="bg-light-orange text-black p-2 mx-1"
                            pill
                          >
                            {val}
                          </Badge>
                        ))}
                      </div>
                      <div className="p-2 border-bottom">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputZip">Qty</Label>
                              <InputField
                                type="number"
                                name={`purchaseItems.[${productIndex}].purchaseEntryVariants.[${index}].quantity`}
                                placeholder="Enter Quantity"
                                onChange={e =>
                                  onChangeEventOfVariants(e, index, "quantity")
                                }
                                // onBlur={validation.handleBlur}
                                value={variant.quantity}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label htmlFor="formrow-InputCity">Unit</Label>
                              <Input
                                type="select"
                                name={`purchaseItems.[${productIndex}].purchaseEntryVariants.[${index}].unit`}
                                placeholder="Unit"
                                onChange={e =>
                                  onChangeEventOfVariants(e, index, "unit")
                                }
                                onBlur={validation.handleBlur}
                                value={variant.unit || ""}
                              >
                                <option value="">Select Unit</option>
                                {allData?.units.map(val => (
                                  <option value={val.name} key={val.name}>
                                    {val.name}
                                  </option>
                                ))}
                              </Input>

                              {/* {validation.touched.purchaseItems?.[productIndex]
                                ?.purchaseEntryVariants?.[index]?.unit &&
                              validation.errors.purchaseItems?.[productIndex]
                                ?.purchaseEntryVariants?.[index]?.unit ? (
                                <span className="text-danger">
                                  {
                                    errors?.purchaseItems[productIndex]
                                      ?.purchaseEntryVariants[index]?.unit
                                  }
                                </span>
                              ) : null} */}
                            </div>
                          </Col>
                        </Row>
                        <div
                          className="text-danger cursor-pointer"
                          onClick={() => {
                            handleRemoveVariants(index)
                          }}
                        >
                          <span>
                            <i className="bx bx-minus-circle mx-1" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <Row>
                    <Col>
                      <div className="text-center mt-3">
                        <button
                          type="button"
                          className="btn btn-primary w-100 btn-lg ms-2"
                          onClick={() => {
                            setIsVariantSubmit(true)
                            toggle()
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            )}
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default ProductAttribute
