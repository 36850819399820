import React from 'react'
import { Spinner } from "reactstrap";

function SpinnerComponent({ size, color, className }) {
    return (
        <React.Fragment>
            <Spinner color={color} size={size} className={className}>Loading...</Spinner>
        </React.Fragment>
    )
}

export default SpinnerComponent
