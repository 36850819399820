import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import {
  createJobberRecord,
  fetchEditedJobber,
  updateJobberRecord,
} from "helpers/backendHelpers/jobber"
import { getUserInfo } from "helpers/authHelper"
import { SaveToast } from "components/Common/SaveToast"
import { removeEmptyKeys } from "util/RemoveKeysValue"
import SpinnerComponent from "components/Common/Spinner"
import { InputField } from "components/Common/InputField"
import DatePicker from "components/Common/DatePicker"

const AddJobber = props => {
  //meta title
  document.title = "Jobber | VH BROTHER"

  // fetch params from URL
  const { id, type } = useParams()

  // initialState
  const initialState = {
    // _id: "",
    companyId: "",
    userId: "",
    jobberCode: "",
    partyName: "",
    dateOfJoining: "",
    ownerName: "",
    address: "",
    GSTNo: "",
    mobileNo: "",
    pointOfContact: {
      name: "",
      mobileNo: "",
    },
    referenceContact: {
      name: "",
      mobileNo: "",
    },
    userName: "",
    password: "",
  }
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(initialState)

  // Fetch Edited Jobber Record
  const fetchEditedJobberRecord = async () => {
    if (type == "edit") {
      setLoading(true)
      try {
        const response = await fetchEditedJobber(id)
        const result = response.payload.data[0]
        setForm(result)
        // setForm({ ...form, companyId: result.companyId._id.toString() })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        return error
      }
    }
  }
  //  fetch userId From Localstorage
  useEffect(() => {
    const userId = getUserInfo()
    setForm({ ...form, userId: userId._id })
    fetchEditedJobberRecord()
  }, [])

  // handle create jobber
  const handleAddJobberData = async jobberData => {
    setLoading(true)
    try {
      const response = await createJobberRecord(jobberData)
      const message = response.message
      SaveToast({ message, type: "success" })
      setLoading(false)
      props.history.push(`/jobber`)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  // handle update jobber
  const handleUpdateJobberData = async (id, jobberData) => {
    setLoading(true)
    try {
      const response = await updateJobberRecord(id, jobberData)
      props.history.push(`/jobber`)
      const message = response.message
      SaveToast({ message, type: "success" })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content h-screen">
        <div className="container-fluid">
          <Breadcrumb title="Jobber" breadcrumbItem="Jobber" />
        </div>
        <div>
          <Formik
            initialValues={form}
            enableReinitialize
            validationSchema={Yup.object().shape({
              GSTNo: Yup.string()
                .matches(
                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                  "invalid Gst"
                )
                .nullable(),
              partyName: Yup.string().required("Please Enter jobber name"),
              ownerName: Yup.string().required("Please Enter owner name"),
              jobberCode: Yup.string().required("Please Enter jobber code"),
              address: Yup.string().required("Please Enter address"),
              ...(type === "add" && {
                userName: Yup.string().required("Please Enter userName"),
              }),
              ...(type === "add" && {
                password: Yup.string().required("Please Enter Password"),
              }),
              mobileNo: Yup.string()
                .required("Please Enter mobile number")
                .matches(/^[6-9]\d{9}$/, "Enter valid mobile number")
                .nullable(),
              pointOfContact: Yup.object().shape({
                mobileNo: Yup.string()
                  .matches(/^[6-9]\d{9}$/, "Enter valid mobile number")
                  .nullable(),
              }),
              referenceContact: Yup.object().shape({
                mobileNo: Yup.string()
                  .matches(/^[6-9]\d{9}$/, "Enter valid mobile number")
                  .nullable(),
              }),
            })}
            onSubmit={async values => {
              removeEmptyKeys(values)
              for (let key in values) {
                if (key == "mobileNo") {
                  values[key] = values[key].toString()
                }

                // for nested object
                for (let nested_key in values[key]) {
                  if (nested_key == "mobileNo") {
                    values[key][nested_key] = values[nested_key].toString()
                  }
                }
              }
              if (type == "edit") {
                return handleUpdateJobberData(id, values)
              } else return handleAddJobberData(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setValues,
              setFieldValue,
            }) => {
              return (
                <div>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <SpinnerComponent size="md" color="dark" />
                    </div>
                  ) : (
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">
                              {type == "edit"
                                ? "Update Jobber"
                                : "Create Jobber"}
                            </CardTitle>
                            <Form
                              onSubmit={async e => {
                                e.preventDefault()
                                const submitted = await handleSubmit()
                                return false
                              }}
                            >
                              <div className="border-bottom py-2">
                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputCity">
                                        Jobber Code
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="jobberCode"
                                        placeholder="Enter Jobber Code"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.jobberCode || ""}
                                        invalid={
                                          touched.jobberCode &&
                                            errors.jobberCode
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.jobberCode &&
                                        errors.jobberCode ? (
                                        <FormFeedback type="invalid">
                                          {errors.jobberCode}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Jobber Name
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="partyName"
                                        placeholder="Enter Jobber Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.partyName || ""}
                                        invalid={
                                          touched.partyName && errors.partyName
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.partyName && errors.partyName ? (
                                        <FormFeedback type="invalid">
                                          {errors.partyName}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Owner Name
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="ownerName"
                                        placeholder="Enter Owner Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerName || ""}
                                        invalid={
                                          touched.ownerName && errors.ownerName
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.ownerName && errors.ownerName ? (
                                        <FormFeedback type="invalid">
                                          {errors.ownerName}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputZip">
                                        Mobile Number
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="number"
                                        name="mobileNo"
                                        placeholder="Enter Mobile Number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobileNo || ""}
                                        invalid={
                                          touched.mobileNo && errors.mobileNo
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.mobileNo && errors.mobileNo ? (
                                        <FormFeedback type="invalid">
                                          {errors.mobileNo}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        GST Number
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="GSTNo"
                                        placeholder="Enter GST Number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.GSTNo || ""}
                                        invalid={
                                          touched.GSTNo && errors.GSTNo
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.GSTNo && errors.GSTNo ? (
                                        <FormFeedback type="invalid">
                                          {errors.GSTNo}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputCity">
                                        Address
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="address"
                                        placeholder="Enter Full Address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address || ""}
                                        invalid={
                                          touched.address && errors.address
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.address && errors.address ? (
                                        <FormFeedback type="invalid">
                                          {errors.address}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-InputCity">
                                        Date Of Joining
                                      </Label>
                                      <DatePicker
                                        value={Date.parse(
                                          values.dateOfJoining || ""
                                        )}
                                        onChange={dates => {
                                          setFieldValue(
                                            "dateOfJoining",
                                            dates[0].toISOString()
                                          )
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              <div className="border rounded-3 p-3 mt-3 py-2">
                                <Row>
                                  <Col md={6} className="">
                                    <div>
                                      <CardTitle className="my-4">
                                        Point Of Contact Details
                                      </CardTitle>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Name
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="pointOfContact.name"
                                          placeholder="Enter Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values?.pointOfContact?.name || ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputZip">
                                          Mobile Number
                                        </Label>
                                        <InputField
                                          type="number"
                                          name="pointOfContact.mobileNo"
                                          placeholder="Enter Mobile Number"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.pointOfContact?.mobileNo ||
                                            ""
                                          }
                                          invalid={
                                            touched.pointOfContact?.mobileNo &&
                                              errors.pointOfContact?.mobileNo
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.pointOfContact?.mobileNo &&
                                          errors.pointOfContact?.mobileNo ? (
                                          <FormFeedback type="invalid">
                                            {errors.pointOfContact?.mobileNo}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="">
                                      <CardTitle className="my-4 border-top-1 border-primary">
                                        Reference Contact Details
                                      </CardTitle>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Name
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="referenceContact.name"
                                          placeholder="Enter Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.referenceContact?.name || ""
                                          }
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputZip">
                                          Mobile Number
                                        </Label>

                                        <InputField
                                          type="number"
                                          name="referenceContact.mobileNo"
                                          placeholder="Enter Mobile Number"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={
                                            values.referenceContact?.mobileNo ||
                                            ""
                                          }
                                          invalid={
                                            touched.referenceContact
                                              ?.mobileNo &&
                                              errors.referenceContact?.mobileNo
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.referenceContact?.mobileNo &&
                                          errors.referenceContact?.mobileNo ? (
                                          <FormFeedback type="invalid">
                                            {errors.referenceContact?.mobileNo}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              {type === "add" && (
                                <div className="border-bottom py-2">
                                  <Row>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          User name
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="userName"
                                          placeholder="Enter user name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.userName || ""}
                                          invalid={
                                            touched.userName && errors.userName
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.userName && errors.userName ? (
                                          <FormFeedback type="invalid">
                                            {errors.userName}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Password
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="password"
                                          placeholder="Enter password"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.password || ""}
                                          invalid={
                                            touched.password && errors.password
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.password && errors.password ? (
                                          <FormFeedback type="invalid">
                                            {errors.password}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              )}

                              <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-danger w-md my-4 mx-2"
                                  onClick={() => props.history.push("/jobber")}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success w-md my-4 "
                                >
                                  {type == "edit" ? "Update" : "Create"}
                                </button>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddJobber
