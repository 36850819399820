import React from 'react'
import { Label, Col } from "reactstrap";
import Dropzone from 'react-dropzone';

function DropImage({ onChange }) {
    return (
        <React.Fragment>
            <Col md={6}>
                <Label htmlFor="formrow-InputCity">Product Image</Label>
                <Dropzone
                    onDrop={onChange}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </Col>
        </React.Fragment>
    )
}

export default DropImage
