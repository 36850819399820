import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

import Dashboard from "../pages/Dashboard/index"

import ProductList from "pages/products/ProductList"
import AddProduct from "pages/products/AddProduct"

import AddManufacture from "pages/Manufacture/AddManufacturer"
import ManufactureList from "pages/Manufacture/ManufactureList"

import Jobber from "../pages/Jobber/JobberList"
import AddJobber from "pages/Jobber/AddJobber"
import SubCompany from "../pages/SubCompany/SubCompanyList"
import AddSubCompany from "pages/SubCompany/SubCompany"
import PurchaseEntryList from "pages/PurchaseEntry"
import AddPurchaseEntry from "pages/PurchaseEntry/AddPurchaseEntry"
import Reports from "pages/Reports/Reports"
import { getUserInfo } from "helpers/authHelper"
const loggedUserData = getUserInfo()
const authProtectedRoutes = [
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  { path: "/dashboard", component: Dashboard },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  // Jobber
  { path: "/jobber", component: Jobber },
  { path: "/jobber/:type/:id?", component: AddJobber },

  // Manufacture
  { path: "/manufacturer", component: ManufactureList },
  { path: "/manufacturer/:type/:id?", component: AddManufacture },

  // products
  { path: "/product", component: ProductList },
  { path: "/product/:type/:id?", component: AddProduct },

  // PurchaseEntry
  { path: "/purchase-entry", component: PurchaseEntryList },
  { path: "/purchase-entry/:type/:id?", component: AddPurchaseEntry },

  // Sub Company
  { path: "/subCompany", component: SubCompany },
  { path: "/subCompany/:type/:id?", component: AddSubCompany },

  // Reports
  { path: "/reports", component: Reports },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

const Menu = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "bx bxs-dashboard",
  },
  {
    path: "/jobber",
    name: "Jobber",
    icon: "bx bx-home-circle",
    activeName: "jobber",
  },
  {
    path: "/product",
    name: "Product",
    icon: "bx bx-shopping-bag",
    activeName: "product",
  },
  {
    path: "/manufacturer",
    name: "Manufacturer",
    icon: "fas fa-industry",
    activeName: "manufacturer",
  },
  {
    path: "/purchase-entry",
    name: "Purchase Entry",
    icon: "bx bx-money",
    activeName: "purchase entry",
  },

  ...(loggedUserData?.role === "admin"
    ? [
        {
          path: "/subCompany",
          name: "Sub Company",
          icon: "bx bxs-report",
          activeName: "Sub Company",
        },
      ]
    : []),
  {
    path: "/reports",
    name: "Reports",
    icon: "bx bxs-report",
    activeName: "Reports",
  },
]

export { publicRoutes, authProtectedRoutes, Menu }
