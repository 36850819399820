import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import {
  createSubCompanyRecord,
  fetchEditedSubCompany,
  updateSubCompanyRecord,
} from "helpers/backendHelpers/sub_company"
import { getUserInfo } from "helpers/authHelper"
import { SaveToast } from "components/Common/SaveToast"
import { removeEmptyKeys } from "util/RemoveKeysValue"
import SpinnerComponent from "components/Common/Spinner"
import { InputField } from "components/Common/InputField"

const AddSubCompany = props => {
  //meta title
  document.title = "Sub Company | VH BROTHER"

  // fetch params from URL
  const { id, type } = useParams()

  // initialState
  const initialState = {
    name: "",
    GSTNo: "",
  }
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState(initialState)

  // Fetch Edited Sub Company Record
  const fetchEditedSubCompanyRecord = async () => {
    if (type == "edit") {
      setLoading(true)
      try {
        const response = await fetchEditedSubCompany(id)
        const result = response.payload.data[0]
        setForm(result)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        return error
      }
    }
  }
  //  fetch userId From Localstorage
  useEffect(() => {
    const userId = getUserInfo()
    setForm({ ...form, userId: userId._id })
    fetchEditedSubCompanyRecord()
  }, [])

  // handle create subCompany
  const handleAddSubCompanyData = async subCompanyData => {
    setLoading(true)
    try {
      const response = await createSubCompanyRecord(subCompanyData)
      const message = response.message
      SaveToast({ message, type: "success" })
      setLoading(false)
      props.history.push(`/subCompany`)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  // handle update subCompany
  const handleUpdateSubCompanyData = async (id, subCompanyData) => {
    setLoading(true)
    try {
      const response = await updateSubCompanyRecord(id, subCompanyData)
      props.history.push(`/subCompany`)
      const message = response.message
      SaveToast({ message, type: "success" })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }
  return (
    <React.Fragment>
      <div className="page-content h-screen">
        <div className="container-fluid">
          <Breadcrumb title="Sub Company" breadcrumbItem="Sub Company" />
        </div>
        <div>
          <Formik
            initialValues={form}
            enableReinitialize
            validationSchema={Yup.object().shape({
              GSTNo: Yup.string()
                .matches(
                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                  "invalid Gst"
                )
                .nullable(),
              name: Yup.string().required("Please Enter sub company name"),
            })}
            onSubmit={async values => {
              removeEmptyKeys(values)
              // handle submit and update event
              if (type == "edit") {
                return handleUpdateSubCompanyData(id, values)
              } else return handleAddSubCompanyData(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setValues,
              setFieldValue,
            }) => {
              return (
                <div>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      <SpinnerComponent size="md" color="dark" />
                    </div>
                  ) : (
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <CardBody>
                            <CardTitle className="mb-4">
                              {type == "edit"
                                ? "Update Sub Company"
                                : "Create Sub Company"}
                            </CardTitle>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                handleSubmit()
                                return false
                              }}
                            >
                              <div className="border-bottom py-2">
                                <Row>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        Sub Company Name
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="name"
                                        placeholder="Enter Sub Company Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name || ""}
                                        invalid={
                                          touched.name && errors.name
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.name && errors.name ? (
                                        <FormFeedback type="invalid">
                                          {errors.name}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={4}>
                                    <div className="mb-3">
                                      <Label htmlFor="formrow-firstname-Input">
                                        GST No
                                      </Label>
                                      <InputField
                                        type="text"
                                        name="GSTNo"
                                        placeholder="Enter GST No"
                                        onChange={handleChange}
                                        value={values.GSTNo || ""}
                                        invalid={
                                          touched.GSTNo && errors.GSTNo
                                            ? true
                                            : false
                                        }
                                      />
                                      {touched.GSTNo && errors.GSTNo ? (
                                        <FormFeedback type="invalid">
                                          {errors.GSTNo}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="d-flex justify-content-center">
                                <button
                                  type="button"
                                  className="btn btn-danger w-md my-4 mx-2"
                                  onClick={() =>
                                    props.history.push("/subCompany")
                                  }
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-success w-md my-4 "
                                >
                                  {type == "edit" ? "Update" : "Create"}
                                </button>
                              </div>
                            </Form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </div>
              )
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddSubCompany
