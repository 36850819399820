import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  Input,
  FormFeedback,
  Spinner,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import {
  createProduct,
  fetchEditedProductRecord,
  fetchUnit,
  updateProductRecord,
} from "helpers/backendHelpers/product"
import Dropzone from "react-dropzone"
import { addFileUpload } from "helpers/backendHelper"
import { removeEmptyKeys } from "util/RemoveKeysValue"
import SpinnerComponent from "components/Common/Spinner"
import { InputField } from "components/Common/InputField"
import DropImage from "components/Common/DropImage"

const AddProduct = props => {
  //meta title
  document.title = "Product | VH BROTHER"

  // initialState
  const initialState = {
    productName: "",
    productCode: "",
    description: "",
    hsnCode: "",
    gstPercentage: "",
    price: "",
    unit: "",
    productImage: "",
  }

  const [imageValue, setImageValue] = useState("")

  const [loading, setLoading] = useState(false)
  const [imgLoader, setImgLoader] = useState(false)
  const [units, setUnits] = useState([])
  const [form, setForm] = useState(initialState)

  // fetch params from URL
  const { id, type } = useParams()

  // Fetch Edited Product Record
  const fetchEditedProduct = async () => {
    if (type == "edit") {
      setLoading(true)
      try {
        const response = await fetchEditedProductRecord(id)
        const result = response.payload.data[0]
        setImageValue(result.productImage)
        setForm(result)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        return error
      }
    }
  }
  const fetchUnitRecord = async () => {
    try {
      const response = await fetchUnit()
      const result = response.payload
      setUnits(result)
    } catch (error) {
      return error
    }
  }

  //  fetch all Data
  useEffect(() => {
    fetchEditedProduct()
    fetchUnitRecord()
  }, [])

  // handle create product
  const addProductClick = async productData => {
    setLoading(true)
    try {
      const response = await createProduct(productData)
      props.history.push(`/product`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  // handle update product
  const updateProductClick = async (id, productData) => {
    setLoading(true)
    try {
      const response = await updateProductRecord(id, productData)
      props.history.push(`/product`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumb title="product" breadcrumbItem="product" />
          </div>
          <div>
            <Formik
              initialValues={form}
              enableReinitialize
              validationSchema={Yup.object().shape({
                productName: Yup.string().required("Please Enter product name"),
                productCode: Yup.string().required("Please Enter product code"),
                gstPercentage: Yup.string()
                  .required("Please Enter gst")
                  .matches(/^([1-9]\d*|0)$/, "Enter valid gst")
                  .nullable(),
                price: Yup.string()
                  .required("Please Enter product price")
                  .matches(/^([1-9]\d*|0)$/, "Enter valid product price")
                  .nullable(),
                unit: Yup.string().required("Please Enter unit"),
              })}
              onSubmit={async values => {
                removeEmptyKeys(values)
                if (type == "edit") {
                  return updateProductClick(id, values)
                } else return addProductClick(values)
              }}
            >
              {({
                errors,
                values,
                handleBlur,
                handleSubmit,
                handleChange,
                touched,
                setValues,
                setFieldValue,
              }) => {
                // handle file upload
                const handleAcceptedFiles = async acceptedFiles => {
                  setImgLoader(true)
                  let formData = new FormData()
                  formData.append("file", acceptedFiles[0])
                  try {
                    const response = await addFileUpload(formData)
                    const result = response.payload
                    setImageValue(result.url)
                    setImgLoader(false)
                    setFieldValue("productImage", response.payload.url)
                  } catch (error) {
                    setImgLoader(false)
                  }
                }
                return (
                  <div>
                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <SpinnerComponent size="md" className="text-primary" />
                      </div>
                    ) : (
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-4">
                                {type == "edit"
                                  ? "Update Product"
                                  : "Create Product"}
                              </CardTitle>
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  handleSubmit()
                                  return false
                                }}
                              >
                                <div className="border-bottom py-2">
                                  <Row>
                                    {/* Product Name */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Product Name
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="productName"
                                          placeholder="Enter Product Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.productName || ""}
                                          invalid={
                                            touched.productName &&
                                            errors.productName
                                          }
                                        />
                                        {touched.productName &&
                                          errors.productName ? (
                                          <FormFeedback type="invalid">
                                            {errors.productName}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Product Code */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Product Code
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="productCode"
                                          placeholder="Enter Product Code"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.productCode || ""}
                                          invalid={
                                            touched.productCode &&
                                            errors.productCode
                                          }
                                        />
                                        {touched.productCode &&
                                          errors.productCode ? (
                                          <FormFeedback type="invalid">
                                            {errors.productCode}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* HSN Code */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          HSN Code
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="hsnCode"
                                          placeholder="Enter HSN Code"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.hsnCode || ""}
                                          invalid={
                                            touched.hsnCode && errors.hsnCode
                                          }
                                        />
                                        {touched.hsnCode && errors.hsnCode ? (
                                          <FormFeedback type="invalid">
                                            {errors.hsnCode}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Default GST */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Default GST%
                                          <span className="text-danger">*</span>{" "}
                                        </Label>
                                        <InputField
                                          type="number"
                                          name="gstPercentage"
                                          placeholder="Enter GST"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.gstPercentage || ""}
                                          invalid={
                                            touched.gstPercentage &&
                                            errors.gstPercentage
                                          }
                                        />
                                        {touched.gstPercentage &&
                                          errors.gstPercentage ? (
                                          <FormFeedback type="invalid">
                                            {errors.gstPercentage}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Price */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Price
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="number"
                                          name="price"
                                          placeholder="Enter Price"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.price || ""}
                                          invalid={
                                            touched.price && errors.price
                                          }
                                        />
                                        {touched.price && errors.price ? (
                                          <FormFeedback type="invalid">
                                            {errors.price}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Unit */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Unit
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                          name="unit"
                                          className="form-select"
                                          value={values.unit}
                                          onChange={handleChange}
                                          // onBlur={handleBlur}
                                          invalid={touched.unit && errors.unit}
                                        // invalid={errors.unit && true}
                                        // onBlur={() => {
                                        //     console.log("name");
                                        // }}
                                        >
                                          <option value="">Select Unit</option>
                                          {units.map((unit, index) => (
                                            <option
                                              key={index}
                                              value={unit.name}
                                            >
                                              {unit.name}
                                            </option>
                                          ))}
                                        </select>
                                        {touched.unit && errors.unit ? (
                                          <FormFeedback>
                                            {errors.unit}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Description */}
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Description
                                        </Label>
                                        <Input
                                          type="textarea"
                                          className="form-control"
                                          name="description"
                                          placeholder="Enter Description"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.description || ""}
                                          invalid={
                                            touched.description &&
                                            errors.description
                                          }
                                          maxLength="225"
                                          rows="3"
                                        />
                                        {touched.description &&
                                          errors.description ? (
                                          <FormFeedback type="invalid">
                                            {errors.description}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    {/* Drop Image */}
                                    <Col md={6}>
                                      <Row>
                                        <DropImage
                                          onChange={handleAcceptedFiles}
                                        />
                                        <Col
                                          md={6}
                                          className="d-flex justigy-content-start align-items-center"
                                        >
                                          <div
                                            className="dropzone-previews mt-3 w-100"
                                            id="file-previews"
                                          >
                                            {imgLoader ? (
                                              <div className="">
                                                <SpinnerComponent
                                                  size="sm"
                                                  color="dark"
                                                />
                                                <span> Uploading...</span>
                                              </div>
                                            ) : (
                                              <div>
                                                {imageValue && (
                                                  <img
                                                    data-dz-thumbnail=""
                                                    height="100"
                                                    style={{
                                                      width: "218px",
                                                      height: "228px",
                                                      objectFit: "cover",
                                                    }}
                                                    className="rounded bg-light cover"
                                                    alt={imageValue}
                                                    src={imageValue}
                                                  />
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-danger w-md my-4 mx-2"
                                    onClick={() =>
                                      props.history.push("/product")
                                    }
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-success w-md my-4 "
                                  >
                                    {type == "edit" ? "Update" : "Create"}
                                  </button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </div>
                )
              }}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default AddProduct
