import Breadcrumb from "components/Common/Breadcrumb"
import { SaveToast } from "components/Common/SaveToast"
import TableContainer from "components/Common/TableContainer"
import { SimpleStringValue, FormatDate } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import {
  deletePurchaseEntry,
  getPurchaseEntry,
} from "helpers/backendHelpers/purchase_entry"
import ConfirmationModal from "components/Common/ConfirmationModal"
import { FaStackOverflow } from "react-icons/fa"

const PurchaseEntryList = props => {
  const [purchase, setPurchase] = useState([])
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [id, setId] = useState("")

  useEffect(() => {
    fetchAllPurchaseEntry()
  }, [])

  // fetch all products
  const fetchAllPurchaseEntry = async () => {
    setLoading(true)
    try {
      const response = await getPurchaseEntry()
      setPurchase(response.payload.data)
      setLoading(false)
    } catch (error) {
      SaveToast({ type: "error", message: error?.response?.data?.message })
      setLoading(false)
    }
  }

  const handleDeletePurchase = async () => {
    try {
      setLoading(true)
      await deletePurchaseEntry(id)
      fetchAllPurchaseEntry()
      setDeleteModal(false)
      setLoading(false)
    } catch (error) {
      return error
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Manufacturer Name",
        accessor: "manufacture.partyName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Jobber Name",
        accessor: "jobber.partyName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Delivery Person name",
        accessor: "deliveryManName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Purchase Invoice No",
        accessor: "purchaseInvoiceNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Date",
        accessor: "purchaseEntryDate",
        filterable: true,
        Cell: cellProps => {
          return <FormatDate {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/purchase-entry/view/" + cellProps.row.original._id
                  )
                }}
              >
                <i className="fas fa-file-pdf font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/purchase-entry/edit/" + cellProps.row.original._id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  setId(cellProps.row.original._id)
                  setDeleteModal(true)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <Container fluid>
        <ConfirmationModal
          show={deleteModal}
          onClick={handleDeletePurchase}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
        <Breadcrumb title="purchase" breadcrumbItem="purchase" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={purchase}
                  isSearchable={true}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  addButtonLabel={"Add Purchase Entry"}
                  handleAddButtonClick={() =>
                    props.history.push("/purchase-entry/add")
                  }
                  dataFetchLoading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PurchaseEntryList
