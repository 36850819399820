import React from "react"
import { FormFeedback, Input } from "reactstrap"
import { FormControl, MenuItem, Select } from "@mui/material"
import { discountType } from "util/enum"

function InputField({
  type,
  name,
  placeholder,
  onBlur,
  onChange,
  value,
  invalid,
  disabled,
}) {
  return (
    <React.Fragment>
      <Input
        type={type}
        name={name}
        className="form-control"
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        invalid={invalid}
      />
    </React.Fragment>
  )
}

const InputType = ({ onChange, item, name }) => {
  return (
    <FormControl
      variant="standard"
      sx={{
        m: 1,
        minWidth: 55,
        position: "absolute",
        top: "0",
        right: "0",
        marginRight: "0",
        margin: "4px",
      }}
    >
      <Select
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: "none" },
          "&::before": {
            // Targeting the ::before pseudo-element
            display: "none", // Setting display to none
          },
        }}
        name={name}
        style={{ paddingLeft: "16px" }}
        size="small"
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={item.discountType}
        onChange={onChange}
      >
        <MenuItem value={discountType.PERCENTAGE}>%</MenuItem>
        <MenuItem value={discountType.AMOUNT}>₹</MenuItem>
      </Select>
    </FormControl>
  )
}

// handleValidation function
const handleValidation = (validation, name, key, index) => {
  if (
    validation.touched[key] &&
    validation.touched[key][index] &&
    validation.touched[key][index][name] &&
    validation?.errors[key] &&
    validation?.errors[key][index] &&
    validation?.errors[key][index][name]
  ) {
    return (
      <span className="text-danger">
        {validation?.errors[key][index][name]}
      </span>
    )
  }

  if (validation.touched[name] && validation.errors[name]) {
    return <span className="text-danger">{validation.errors[name]}</span>
  }
}

export { InputField, InputType, handleValidation }
