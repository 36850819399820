import React, { useEffect, useMemo, useRef, useState } from "react"
import Breadcrumb from "components/Common/Breadcrumb"
import {
  Container,
  Row,
  Label,
  Col,
  CardBody,
  Card,
  FormGroup,
  Input,
  NavItem,
  TabPane,
  Badge,
  NavLink,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import { FormatDate, SimpleStringValue } from "helpers/common_helper_functions"
import { fetchManufactureData } from "helpers/backendHelpers/manufacture"
import Flatpickr from "react-flatpickr"
import {
  fetchJobberDetail,
  getSubCompanyDetails,
} from "helpers/backendHelpers/purchase_entry"
import moment from "moment"
import { SaveToast } from "components/Common/SaveToast"
import { createStringQueryParams } from "components/Common/reports"
import exportExcelFile from "components/Common/exportExcelFile"
import SingleSelect from "components/Common/SingleSelect"
import classnames from "classnames"
import { reportType } from "util/enum"
import { fetchAllReports } from "helpers/backendHelpers/reports"

function Reports() {
  // state manage
  const [loading, setLoading] = useState(false)
  const [onchangeVal, setOnchangeVal] = useState({})
  const [fetchData, setFetchData] = useState({
    reports: { url: "", data: [] },
    manufacturer: [],
    jobber: [],
    subCompany: [],
  })
  const flatPicker = useRef(null)
  const [activeTab, setActiveTab] = useState("1")

  // fetch all record from api
  const fetchAllRecords = async () => {
    setLoading(true)
    try {
      await fetchFilteredRecord("", reportType.Fabric.url)
      const manufacturerResponse = await fetchManufactureData()
      const jobberResponse = await fetchJobberDetail()
      const companyResponse = await getSubCompanyDetails()

      setFetchData(prevState => ({
        ...prevState,
        manufacturer: manufacturerResponse?.payload.data.map(item => ({
          label: item.partyName,
          value: item._id,
        })),
        jobber: jobberResponse?.payload.data.map(item => ({
          label: item.partyName,
          value: item._id,
        })),
        subCompany: companyResponse?.payload.data.map(item => ({
          label: item.name,
          value: item._id,
        })),
      }))

      setLoading(false)
    } catch (error) {
      console.log("error", error)
      setLoading(false)
    }
  }

  // fetch data filter wise
  const fetchFilteredRecord = async (value, reportTypeUrl) => {
    try {
      setLoading(true)
      const response = await fetchAllReports(value, reportTypeUrl)
      setFetchData(prevState => ({
        ...prevState,
        reports: response?.payload,
      }))
      setLoading(false)
    } catch (error) {
      console.log("error", error)
    }
  }

  console.log("fetchData", fetchData)

  useEffect(() => {
    let string = createStringQueryParams(onchangeVal)
    string = string.slice(1)
    if (Object.keys(onchangeVal).length > 0) {
      fetchFilteredRecord(
        string,
        activeTab == "1" ? reportType.Fabric.url : reportType.Purchase.url
      )
    } else {
      fetchAllRecords()
    }
  }, [onchangeVal])

  // handle date range picker
  const dateOnChange = event => {
    if (event !== null) {
      let [startDate, endDate] = event
      startDate = startDate.toISOString()
      endDate = endDate.toISOString()
      setOnchangeVal({
        ...onchangeVal,
        startDate: startDate,
        endDate: endDate,
      })
    }
  }

  // add query handling
  const onChangeEvent = async (name, value) =>
    setOnchangeVal(prevState => ({ ...prevState, [name]: value }))

  // remove query handling
  const handleRemoveChange = name =>
    setOnchangeVal(prevState => ({ ...prevState, [name]: "" }))

  // pass data in table
  const FabricDeliveryColumn = useMemo(
    () => [
      {
        Header: "Invoice No",
        accessor: "purchaseInvoiceNo",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Company Name",
        accessor: "companyName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Manufacturer Name",
        accessor: "manufacturerName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Jobber Name",
        accessor: "jobberName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Purchase Entry Date",
        accessor: "purchaseEntryDate",
        filterable: true,
        Cell: cellProps => <FormatDate {...cellProps} />,
      },
      {
        Header: "Product Name",
        accessor: "productName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Qty",
        accessor: "qty",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Unit",
        accessor: "unit",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Variant",
        accessor: "variantString",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Delivery Date",
        accessor: "deliveryDate",
        filterable: true,
        Cell: cellProps => <FormatDate {...cellProps} />,
      },
      {
        Header: "Delivery Date",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          const { status } = cellProps.row.original
          return (
            <Badge color={status === "Inward" ? "success" : "danger"}>
              {cellProps.row.original.status}
            </Badge>
          )
        },
      },
    ],
    []
  )

  const PurchaseSummaryColumn = useMemo(
    () => [
      {
        Header: "Invoice No",
        accessor: "purchaseInvoiceNo",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Invoice Date",
        accessor: "purchaseInvoiceDate",
        filterable: true,
        Cell: cellProps => <FormatDate {...cellProps} />,
      },
      {
        Header: "Purchase Entry Date",
        accessor: "purchaseEntryDate",
        filterable: true,
        Cell: cellProps => <FormatDate {...cellProps} />,
      },
      {
        Header: "Sub Company Name",
        accessor: "subCompanyName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Manufacturer Name",
        accessor: "manufacturerName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Bill Amount",
        accessor: "totalAmount",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Discount Amount",
        accessor: "discountAmount",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Taxable Amount",
        accessor: "taxableAmount",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Tax Amount",
        accessor: "taxAmount",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Net Amount",
        accessor: "grandTotal",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
    ],
    []
  )

  // make object to display selection dropdown
  const selectBoxObj = [
    {
      name: "Sub Company",
      options: fetchData.subCompany,
      value: onchangeVal.subCompanyId,
      onchange: (e, { action }) => {
        if (action == "clear") {
          handleRemoveChange("subCompanyId")
        } else onChangeEvent("subCompanyId", e.value, action)
      },
    },
    {
      name: "Manufacturer",
      options: fetchData.manufacturer,
      value: onchangeVal.manufactureId,
      onchange: (e, { action }) => {
        if (action == "clear") {
          handleRemoveChange("manufactureId")
        } else onChangeEvent("manufactureId", e.value, action)
      },
    },
    ...(activeTab == "1"
      ? [
        {
          name: "Jobber",
          options: fetchData.jobber,
          value: onchangeVal.jobberId,
          onchange: (e, { action }) => {
            if (action == "clear") {
              handleRemoveChange("jobberId")
            } else onChangeEvent("jobberId", e.value, action)
          },
        },
      ]
      : []),
  ]

  // object passing in microsoft excel
  const exportExcelData = fetchData?.reports?.data.map(item =>
    activeTab == "1"
      ? {
        "Invoice No": item.purchaseInvoiceNo,
        "Company Name": item.companyName,
        "Manufacturer Name": item.manufacturerName,
        "Jobber Name": item.jobberName,
        "Purchase Entry Date": item.purchaseEntryDate,
        "Product Name": item.productName,
        Qty: item.qty,
        Unit: item.unit,
        Variant: item.variantString,
        "Delivery Date": item.deliveryDate,
        Status: item.status,
      }
      : {
        "Invoice No": item.purchaseInvoiceNo,
        "Invoice Date": item.purchaseInvoiceDate,
        "Purchase Entry Date": item.purchaseEntryDate,
        "Sub Company Name": item.subCompanyName,
        "Manufacturer Name": item.manufacturerName,
        "Bill Amount": item.totalAmount,
        "Discount Amount": item.discountAmount,
        "Taxable Amount": item.taxableAmount,
        "Tax Amount": item.taxAmount,
        "Net Amount": item.grandTotal,
      }
  )

  // export data in microsoft excel
  const exportToExcel = () => {
    const date = new Date()
    const fileName = onchangeVal.startDate
      ? `Report_ ${moment(onchangeVal.startDate).format(
        "DD_MM_YYYY"
      )}_to_${moment(onchangeVal.endDate).format("DD_MM_YYYY")}`
      : moment(date.toISOString()).format("DD_MM_YYYY")

    // call function to export data in microsoft excel
    if (exportExcelData.length > 0) {
      exportExcelFile(exportExcelData, fileName)
    } else {
      SaveToast({ type: "error", message: "no data to export" })
    }
  }

  //* toggle tab inquiry and plan
  const toggleTab = tab => {
    if (tab === "1") {
      fetchFilteredRecord("", reportType.Fabric.url)
    } else {
      fetchFilteredRecord("", reportType.Purchase.url)
    }
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Reports" breadcrumbItem="Reports" />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={exportToExcel}
                    className="btn w-md my-4 btn-success mx-2 d-flex justify-content-center"
                  >
                    <i className="bx bx-export font-size-18 mx-1" />
                    <span>Export</span>
                  </button>
                  <button className="btn w-md my-4 mx-2 d-flex justify-content-center btn-primary-blue">
                    {fetchData?.reports?.url ? (
                      <a
                        href={fetchData?.reports?.url}
                        target="_blank"
                        className="text-white"
                      >
                        <span>Export PDF</span>
                        <i className="far fa-file-pdf font-size-13 mx-1" />
                      </a>
                    ) : (
                      <div
                        onClick={() =>
                          SaveToast({
                            type: "error",
                            message: "no data to export",
                          })
                        }
                      >
                        <span>Export PDF</span>
                        <i className="far fa-file-pdf font-size-13 mx-1" />
                      </div>
                    )}
                  </button>
                </div>
                <Row>
                  {selectBoxObj.map((items, index) => (
                    <Col md={2} key={index}>
                      <FormGroup className="mb-4">
                        <Label>{items.name}</Label>
                        <SingleSelect
                          value={
                            items?.options[
                            items?.options?.findIndex(
                              val => val?.value === onchangeVal[items?.value]
                            )
                            ]
                          }
                          onChange={items.onchange}
                          options={items.options}
                        />
                      </FormGroup>
                    </Col>
                  ))}
                  {activeTab === "1" && (
                    <>
                      <Col md={2}>
                        <Label>Status</Label>
                        <Input
                          type="select"
                          name="dateFieldForSearch"
                          onChange={e => {
                            // flatPicker.current.flatpickr.open()
                            onChangeEvent("status", e.target.value)
                          }}
                        >
                          <option value="" key="" selected disabled>
                            Select Status
                          </option>
                          <option value="Inward">Inward</option>
                          <option value="In Transist">In Transist</option>
                        </Input>
                      </Col>
                      <Col md={2}>
                        <Label>Date Field For Search</Label>
                        <Input
                          type="select"
                          name="dateFieldForSearch"
                          onChange={e => {
                            // flatPicker.current.flatpickr.open()
                            onChangeEvent("dateFieldForSearch", e.target.value)
                          }}
                        >
                          <option value="" key="" selected disabled>
                            Select Date
                          </option>
                          <option value="purchaseEntryDate">
                            Purchase Entry Date
                          </option>
                          <option value="actualDeliveryDate">
                            Delivery Date
                          </option>
                        </Input>
                      </Col>
                    </>
                  )}

                  <Col md={2}>
                    <Label>Date </Label>
                    <Flatpickr
                      ref={flatPicker}
                      value={`${onchangeVal.startDate} to ${onchangeVal.endDate}`}
                      className="form-control d-block"
                      style={{
                        background: "white",
                      }}
                      placeholder="DD/MM/YYYY"
                      options={{
                        mode: "range",
                        dateFormat: "d/m/Y",
                      }}
                      onChange={e => {
                        dateOnChange(e)
                      }}
                    />
                  </Col>
                </Row>

                <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1")
                      }}
                    >
                      {reportType.Fabric.FABRIC_DELIVERY}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2")
                      }}
                    >
                      {reportType.Purchase.PURCHASE_SUMMARY}
                    </NavLink>
                  </NavItem>
                </ul>

                {activeTab == "1" ? (
                  <TabPane
                    activeTab={activeTab}
                    tabId="1"
                    id="processing"
                    className="p-3"
                  >
                    <div>
                      <TableContainer
                        columns={FabricDeliveryColumn}
                        data={fetchData?.reports?.data}
                        isSearchable={true}
                        isGlobalFilter={false}
                        isReport={true}
                        dataFetchLoading={loading}
                      />
                    </div>
                  </TabPane>
                ) : (
                  <TabPane
                    activeTab={activeTab}
                    tabId="2"
                    id="processing"
                    className="p-3"
                  >
                    <div>
                      <TableContainer
                        columns={PurchaseSummaryColumn}
                        data={fetchData?.reports?.data}
                        isSearchable={true}
                        isGlobalFilter={false}
                        isReport={true}
                        dataFetchLoading={loading}
                      />
                    </div>
                  </TabPane>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Reports
