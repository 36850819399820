import { del, get, post, put } from "helpers/api_helper"
import { getApiConfig } from "helpers/authHelper"
import * as url from "helpers/url_helper"

// fetch company details
export const fetchCompanyDetail = () => {
  return get(url.COMPANY, getApiConfig())
}

export const getSubCompanyDetails = () => {
  return get(url.SUB_COMPANY, getApiConfig())
}

// fetch manufacturer details
export const fetchManufacturerDetail = () => {
  return get(url.MANUFACTURE, getApiConfig())
}

// fetch jobber details
export const fetchJobberDetail = () => {
  return get(url.JOBBER, getApiConfig())
}

// fetch product details
export const fetchProductDetails = () => {
  return get(url.PRODUCTS, getApiConfig())
}

// fetch purchase entry details
export const getPurchaseEntry = () => {
  return get(url.PURCHASE_ENTRY, getApiConfig())
}

// fetch single purchase entry details
export const getPurchaseEntryEdit = id => {
  return get(`/purchase-entry/items/${id}`, getApiConfig())
}

// delete purchase entry
export const deletePurchaseEntry = id => {
  return del(`${url.PURCHASE_ENTRY}/${id}`, getApiConfig())
}

// delete purchase entry
export const fetchProductAttribute = id => {
  return get(url.ATTRIBUTE, getApiConfig())
}

// get variants
export const fetchProductVariant = () => {
  return get(url.VARIANTS, getApiConfig())
}

// post variants
export const generateCombination = data => {
  return post(url.GENERATE_COMBINATION, data, getApiConfig())
}

export const createPurchaseEntry = data => {
  return post(url.PURCHASE_ENTRY, data, getApiConfig())
}

export const updatePurchaseEntry = (id, data) => {
  return put(`${url.PURCHASE_ENTRY}/${id}`, data, getApiConfig())
}
