import XLSX from "sheetjs-style";
import FileSaver from 'file-saver'

const exportExcelFile = (exportExcelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(exportExcelData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8' });
    FileSaver.saveAs(data, fileName);
}

export default exportExcelFile
