import { post, axiosApi } from "./api_helper"
import { getApiConfig } from "./authHelper"
import * as url from "./url_helper"

export const loginUser = async data => {
  return await axiosApi
    .post(`${url.USER_LOGIN}/signin`, data)
    .then(response => response.data)
}

// file upload
export const addFileUpload = data => {
  return post(`${url.FILE_UPLOAD}`, data, getApiConfig())
}
