import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

// Fetch Jobber 
export const getAllJobbers = () => {
  return get(url.JOBBER, getApiConfig())
}

// Create Jobber
export const createJobberRecord = (data) => {
  return post(url.JOBBER, data, getApiConfig())
}

// Delete Jobber
export const deleteJobberRecord = (id) => {
  return del(`${url.JOBBER}/${id}`, getApiConfig())
}

// Fetch Edited Jobber
export const fetchEditedJobber = (id) => {
  return get(`${url.JOBBER}?_id=${id}`, getApiConfig())
}

export const updateJobberRecord = (id, data) => {
  return put(`${url.JOBBER}/${id}`, data, getApiConfig())
}

