import { del, get, post, put } from "helpers/api_helper"
import * as url from "../url_helper"
import { getApiConfig } from "helpers/authHelper"

// create Product
export const createProduct = data => {
    return post(url.PRODUCTS, data, getApiConfig());
}

// fetch product
export const fetchAllProducts = () => {
    return get(url.PRODUCTS, getApiConfig());
}

// delete product
export const deleteProductRecord = id => {
    return del(`${url.PRODUCTS}/${id}`, getApiConfig());
}

// fetch edited product
export const fetchEditedProductRecord = id => {
    return get(`${url.PRODUCTS}?_id=${id}`, getApiConfig());
}

// update product
export const updateProductRecord = (id, data) => {
    return put(`${url.PRODUCTS}/${id}`, data, getApiConfig());
}

// update product
export const fetchUnit = () => {
    return get(url.UNIT, getApiConfig());
}


