import { post, get, del, put } from "helpers/api_helper";
import { getApiConfig } from "../authHelper";
import * as url from "../url_helper";

// File Upload
export const addFileUpload = async (data) => {
    return await post(url.FILE_UPLOAD, data, getApiConfig())
}

// Create Manufacture
export const addManufacturedData = async (data) => {
    return await post(url.MANUFACTURE, data, getApiConfig())
}

// Fetch Manufacture
export const fetchManufactureData = async () => {
    return await get(url.MANUFACTURE, getApiConfig())
}

// fetch Edited Manufacture
export const fetchEditedManufacture = async (id) => {
    return await get(`${url.MANUFACTURE}?_id=${id}`, getApiConfig())
}

// Delete Manufacture
export const deleteManufactureRecord = async (id) => {
    return await del(`${url.MANUFACTURE}/${id}`, getApiConfig())
}

// Update Manufacture
export const updateManufacturedData = async (id) => {
    return await del(`${url.MANUFACTURE}/${id}`, getApiConfig())
}

// 
export const updateManufactureRecord = (id, data) => {
    return put(`${url.MANUFACTURE}/${id}`, data, getApiConfig())
}

