
import React from "react"
import { Container } from "reactstrap"
import classNames from "classnames"
import Breadcrumb from "components/Common/Breadcrumb"

const Dashboard = () => {

  //meta title
  document.title = "HomePage | VH BROTHER"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboard" />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
