import React, { useRef } from "react"
import { Container } from "reactstrap"
import ReactToPrint from "react-to-print"
import { Card, CardBody, Col, Row, Table } from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"

const ViewSummary = ({ summary }) => {
  const contentRef = useRef()

  console.log("summary", summary)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumb title="summary" breadcrumbItem="Invoice Detail" />

          <PrintContent ref={contentRef} summary={summary} />
          <div className="float-end mt-3">
            <ReactToPrint
              trigger={() => (
                <button className="btn btn-success  me-2">
                  <i className="fa fa-print" />
                </button>
              )}
              content={() => contentRef.current}
            />
          </div>
          {/* )} */}
        </Container>
      </div>
    </React.Fragment>
  )
}

const PrintContent = React.forwardRef(({ summary }, ref) => {
  return (
    <div ref={ref}>
      <Row id="printableArea" className="mx-1 my-2">
        <Col lg="12">
          <Card>
            <CardBody>
              <div className="invoice-title" style={{ marginBottom: "43px" }}>
                <div>
                  <h4
                    className="m-0 text-center fw-bold position-absolute"
                    style={{ left: "50%", transform: "translatex(-50%)" }}
                  >
                    Purchase Invoice
                  </h4>
                  <h4 className="m-0 font-size-16 float-end">
                    Bill No. # {summary?.purchaseInvoiceNo}
                  </h4>
                </div>
              </div>
              <hr />
              <Row style={{ margin: "0 5px" }}>
                <Col xs="6">
                  <address>
                    <strong>Billed To:</strong>
                    <br />

                    <span>{summary.jobberId?.partyName}</span>
                    {summary?.jobberId?.address && (
                      <>
                        <br />
                        <span>{summary.manufactureId?.address}</span>
                      </>
                    )}
                    <br />
                  </address>
                </Col>
                <Col xs="6" className="text-end">
                  <address>
                    <strong>Billed From:</strong>
                    <br />

                    <span>{summary.manufactureId?.partyName}</span>
                    {summary.manufactureId?.address && (
                      <>
                        <br /> <span>{summary.manufactureId?.address}</span>
                      </>
                    )}
                    <br />
                    {summary.manufactureId?.GSTNo && (
                      <span>{summary.manufactureId?.GSTNo}</span>
                    )}
                  </address>
                </Col>
              </Row>

              <div className="table-responsive">
                <Table className="table-nowrap">
                  <thead>
                    <tr>
                      <th style={{ width: "70px" }}>no</th>
                      <th>Product</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Unit</th>
                      <th>Discount</th>
                      <th>Tax</th>
                      <th className="text-end">Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {summary.purchaseItems.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item.productName}</td>
                        <td>{item.quantity}</td>
                        <td>{item.price}</td>
                        <td>{item.unit}</td>
                        <td>{`${item.discountAmount} (${item.discountPercentage}%)`}</td>
                        <td>{`${item.taxAmount} (${item.taxPercentage}%)`}</td>
                        <td className="text-end">{item.grandTotal}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="7" className="border-0 text-end">
                        <strong>Discount Amount</strong>
                      </td>
                      <td className="border-0 text-end">
                        <h4 className="m-0">
                          {summary?.purchaseItems?.reduce((acc, item) => {
                            return acc + item.discountAmount
                          }, 0)}
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="border-0 text-end">
                        <strong>Tax Amount</strong>
                      </td>
                      <td className="border-0 text-end">
                        <h4 className="m-0">
                          {summary?.purchaseItems?.reduce((acc, item) => {
                            return acc + item.taxAmount
                          }, 0)}
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="7" className="border-0 text-end">
                        <strong>Total Amount</strong>
                      </td>
                      <td className="border-0 text-end">
                        <h4 className="m-0">{summary?.grandTotal}</h4>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
})

export default ViewSummary
