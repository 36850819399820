// remove key and value from object when no value
export function removeEmptyKeys(data) {
    for (let key in data) {
        if (data[key] && typeof data[key] === "object") {
            removeEmptyKeys(data[key])
            if (Object.keys(data[key]).length === 0) delete data[key]
        } else if (
            data[key] === "" ||
            data[key] === null ||
            data[key] === undefined
        ) {
            delete data[key]
        }
        // else if (key === "mobileNo") {
        //     data[key] = data[key].toString()
        // }
    }
}