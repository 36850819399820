import Breadcrumb from "components/Common/Breadcrumb"
import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import { Formik } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import {
  addFileUpload,
  addManufacturedData,
  fetchEditedManufacture,
  updateManufactureRecord,
} from "helpers/backendHelpers/manufacture"
import { useParams } from "react-router-dom"
import { removeEmptyKeys } from "util/RemoveKeysValue"
import SpinnerComponent from "components/Common/Spinner"
import { InputField } from "components/Common/InputField"
import DatePicker from "components/Common/DatePicker"
import DropImage from "components/Common/DropImage"

const AddManufacture = props => {
  //meta title
  document.title = "Manufacturer | VH BROTHER"

  // initialState
  const initialState = {
    _id: "",
    GSTNo: "",
    ownerName: "",
    partyName: "",
    manufactureCode: "",
    address: "",
    dateOfJoining: "",
    mobileNo: "",
    pointOfContact: {
      name: "",
      mobileNo: "",
    },
    referenceContact: {
      name: "",
    },
    udyamCertificate: "",
  }

  const [imageValue, setImageValue] = useState()
  const [loading, setLoading] = useState(false)

  const [imgLoader, setImgLoader] = useState(false)
  const [form, setForm] = useState(initialState)
  const { id, type } = useParams()

  // fetch Edited record
  const fetchEditedManufactureRecord = async () => {
    if (type == "edit") {
      setLoading(true)
      try {
        const response = await fetchEditedManufacture(id)
        const result = response.payload.data[0]
        setImageValue(result?.udyamCertificate)
        setForm(result)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        return error
      }
    }
  }
  useEffect(() => {
    fetchEditedManufactureRecord()
  }, [])

  // handle add record
  const handleAddManufacturedData = async manufacturedData => {
    setLoading(true)
    try {
      const response = await addManufacturedData(manufacturedData)
      props.history.push(`/manufacturer`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  // handle update record
  const handleUpdateManufacturedData = async (id, manufacturedData) => {
    setLoading(true)
    try {
      const response = await updateManufactureRecord(id, manufacturedData)
      props.history.push(`/manufacturer`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      return error
    }
  }

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumb title="Tables" breadcrumbItem="Manufacturer" />
          </div>
          <div>
            <Formik
              initialValues={form}
              enableReinitialize
              validationSchema={Yup.object().shape({
                GSTNo: Yup.string()
                  .matches(
                    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
                    "invalid Gst"
                  )
                  .nullable(),
                ownerName: Yup.string().required("Please Enter Owner Name"),
                partyName: Yup.string().required("Please Enter Party Name"),
                manufactureCode: Yup.string().required(
                  "Please Enter Party Code"
                ),
                address: Yup.string().required("Please Enter Address"),
                mobileNo: Yup.string()
                  .required("Please Enter mobile Number")
                  .matches(/^[6-9]\d{9}$/, "Enter valid mobile number")
                  .nullable(),
                pointOfContact: Yup.object().shape({
                  mobileNo: Yup.string()
                    .matches(/^[6-9]\d{9}$/, "Enter valid mobile number")
                    .nullable(),
                }),
              })}
              onSubmit={async values => {
                removeEmptyKeys(values)
                for (let key in values) {
                  if (key == "mobileNo") {
                    values[key] = values[key].toString()
                  }

                  // for nested object
                  for (let nested_key in values[key]) {
                    if (nested_key == "mobileNo") {
                      values[key][nested_key] = values[nested_key].toString()
                    }
                  }
                }
                if (type == "edit") {
                  return handleUpdateManufacturedData(id, values)
                } else return handleAddManufacturedData(values)
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setValues,
                setFieldValue,
              }) => {
                const handleAcceptedFiles = async acceptedFiles => {
                  setImgLoader(true)
                  let formData = new FormData()
                  formData.append("file", acceptedFiles[0])
                  try {
                    const response = await addFileUpload(formData)
                    const result = response.payload
                    setImageValue(result.url)
                    setFieldValue("udyamCertificate", response.payload.url)
                    setImgLoader(false)
                  } catch (error) {
                    setImgLoader(false)
                    console.error("Error uploading file:", error)
                  }
                }
                return (
                  <div>
                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <SpinnerComponent size="md" color="dark" />
                      </div>
                    ) : (
                      <Row>
                        <Col lg={12}>
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-4">
                                {type == "edit"
                                  ? "Update Manufacturer"
                                  : "Create Manufacturer"}
                              </CardTitle>
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  handleSubmit()
                                  return false
                                }}
                              >
                                <div className="border-bottom py-2">
                                  <Row>
                                    <Col md={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          GST Number
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="GSTNo"
                                          placeholder="Enter GST number"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values?.GSTNo || ""}
                                          invalid={
                                            touched?.GSTNo && errors?.GSTNo
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched?.GSTNo && errors?.GSTNo ? (
                                          <FormFeedback type="invalid">
                                            {errors?.GSTNo}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                          Owner Name
                                          <span className="text-danger">*</span>
                                        </Label>

                                        <InputField
                                          type="text"
                                          name="ownerName"
                                          placeholder="Enter Owner Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.ownerName || ""}
                                          invalid={
                                            touched.ownerName &&
                                              errors.ownerName
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.ownerName &&
                                          errors.ownerName ? (
                                          <FormFeedback type="invalid">
                                            {errors.ownerName}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-email-Input">
                                          Party Name
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="partyName"
                                          placeholder="Enter Party Name"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.partyName || ""}
                                          invalid={
                                            touched.partyName &&
                                              errors.partyName
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.partyName &&
                                          errors.partyName ? (
                                          <FormFeedback type="invalid">
                                            {errors.partyName}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Party Code
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="manufactureCode"
                                          placeholder="Enter Party Code"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.manufactureCode || ""}
                                          invalid={
                                            touched.manufactureCode &&
                                              errors.manufactureCode
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.manufactureCode &&
                                          errors.manufactureCode ? (
                                          <FormFeedback type="invalid">
                                            {errors.manufactureCode}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Address
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="text"
                                          name="address"
                                          placeholder="Enter Full Address"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.address || ""}
                                          invalid={
                                            touched.address && errors.address
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.address && errors.address ? (
                                          <FormFeedback type="invalid">
                                            {errors.address}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputCity">
                                          Date Of Joining
                                        </Label>
                                        <DatePicker
                                          value={Date.parse(
                                            values.dateOfJoining || ""
                                          )}
                                          onChange={dates => {
                                            setFieldValue(
                                              "dateOfJoining",
                                              dates[0].toISOString()
                                            )
                                          }}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={4}>
                                      <div className="mb-3">
                                        <Label htmlFor="formrow-InputZip">
                                          Mobile Number
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <InputField
                                          type="number"
                                          name="mobileNo"
                                          placeholder="Enter Mobile Number"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.mobileNo || ""}
                                          invalid={
                                            touched.mobileNo && errors.mobileNo
                                              ? true
                                              : false
                                          }
                                        />
                                        {touched.mobileNo && errors.mobileNo ? (
                                          <FormFeedback type="invalid">
                                            {errors.mobileNo}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <div className="border rounded-3 p-3 mt-3 py-2">
                                  <Row>
                                    <Col md={6} className="">
                                      <div>
                                        <CardTitle className="my-4">
                                          Point Of Contact Details
                                        </CardTitle>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-InputCity">
                                            Name
                                          </Label>
                                          <InputField
                                            type="text"
                                            name="pointOfContact.name"
                                            placeholder="Enter Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values?.pointOfContact?.name || ""
                                            }
                                          />
                                        </div>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-InputZip">
                                            Mobile Number
                                          </Label>
                                          <InputField
                                            type="number"
                                            name="pointOfContact.mobileNo"
                                            placeholder="Enter Mobile Number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.pointOfContact?.mobileNo ||
                                              ""
                                            }
                                            invalid={
                                              touched.pointOfContact
                                                ?.mobileNo &&
                                                errors.pointOfContact?.mobileNo
                                                ? true
                                                : false
                                            }
                                          />
                                          {touched.pointOfContact?.mobileNo &&
                                            errors.pointOfContact?.mobileNo ? (
                                            <FormFeedback type="invalid">
                                              {errors.pointOfContact?.mobileNo}
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="">
                                        <CardTitle className="my-4 border-top-1 border-primary">
                                          Reference Contact Details
                                        </CardTitle>
                                        <div className="mb-3">
                                          <Label htmlFor="formrow-InputCity">
                                            Name
                                          </Label>
                                          <InputField
                                            type="text"
                                            name="referenceContact.name"
                                            placeholder="Enter Name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.referenceContact?.name ||
                                              ""
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <CardTitle className="my-4 border-top-1 border-primary">
                                  Udyam Certificate
                                </CardTitle>
                                {/* Drop Image */}
                                <Row>
                                  <DropImage onChange={handleAcceptedFiles} />
                                  <Col
                                    md={6}
                                    className="d-flex justigy-content-start align-items-center"
                                  >
                                    <div
                                      className="dropzone-previews mt-3 w-100"
                                      id="file-previews"
                                    >
                                      {imgLoader ? (
                                        <div className="">
                                          <SpinnerComponent
                                            size="sm"
                                            color="dark"
                                          />
                                          <span> Uploading...</span>
                                        </div>
                                      ) : (
                                        <div>
                                          {imageValue && (
                                            <img
                                              data-dz-thumbnail=""
                                              height="100"
                                              style={{
                                                width: "218px",
                                                height: "215px",
                                                objectFit: "cover",
                                              }}
                                              className="rounded bg-light cover"
                                              alt={imageValue}
                                              src={imageValue}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <div className="d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-danger w-md my-4 mx-2"
                                    onClick={() =>
                                      props.history.push("/manufacturer")
                                    }
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="submit"
                                    className="btn btn-success w-md my-4 "
                                  >
                                    {type == "edit" ? "Update" : "Create"}
                                  </button>
                                </div>
                              </Form>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </div>
                )
              }}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    </div>
  )
}

export default withRouter(AddManufacture)

AddManufacture.propTypes = {
  history: PropTypes.object,
}
