import { del, get } from "helpers/api_helper"
import { getApiConfig } from "helpers/authHelper"
import * as url from "helpers/url_helper"

// fetch company details
export const fetchAllReports = (query, reportTypeUrl) => {
  query = query ? query : ""
  return get(`${url.REPORTS}/${reportTypeUrl}?${query}`, getApiConfig())
}

// // fetch company details
// export const fetchFilteredReports = (newUrl) => {
//     return get(`${url.REPORTS}?${newUrl}`, getApiConfig())
// }
