import Breadcrumb from "components/Common/Breadcrumb"
import { SaveToast } from "components/Common/SaveToast"
import TableContainer from "components/Common/TableContainer"
import {
  deleteJobberRecord,
  getAllJobbers,
  updateJobberRecord,
} from "helpers/backendHelpers/jobber"
import { FormatDate, SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Label,
  Input,
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import ConfirmationModal from "components/Common/ConfirmationModal"
import { InputField } from "components/Common/InputField"

const JobberList = props => {
  //meta title
  document.title = "Jobber | VH BROTHER"

  const [jobber, setJobber] = useState([])
  const [loading, setLoading] = useState(false)

  const [jobberId, setJobberId] = useState("")
  const [deleteModalModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [user, setUser] = useState({
    userName: "",
    password: "",
  })

  useEffect(() => {
    fetchJobber()
  }, [])

  // fetch all jobber
  const fetchJobber = async () => {
    setLoading(true)
    try {
      const response = await getAllJobbers()
      setJobber(response.payload.data)
      setLoading(false)
    } catch (error) {
      SaveToast({ type: "error", message: error.response.data.message })
      setLoading(false)
    }
  }

  // add button click
  const handleAddButtonClick = () => {
    props.history.push("/jobber/add")
  }
  const onClickDelete = async () => {
    setDeleteLoading(true)
    try {
      const response = await deleteJobberRecord(jobberId)
      SaveToast({ type: "success", message: response.message })
      fetchJobber()
      setDeleteModal(false)
      setDeleteLoading(false)
    } catch (error) {
      setDeleteLoading(false)
      return error
    }
  }

  // toggle modal
  const toggle = () => {
    setModal(!modal)
  }

  // handle change for jobber user
  const handleChange = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  // update jobber user
  const handleUpdateJobber = async e => {
    e.preventDefault()
    if (user.userName === "") {
      return SaveToast({ type: "error", message: "Please enter user name" })
    }

    if (user.password === "") {
      return SaveToast({ type: "error", message: "Please enter password" })
    }
    try {
      const response = await updateJobberRecord(jobberId, user)
      SaveToast({ type: "success", message: response.message })
    } catch (error) {
      SaveToast({ type: "error", message: error.response.data.message })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Jobber No",
        accessor: "jobberNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Owner Name",
        accessor: "ownerName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Party Name",
        accessor: "partyName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "GST No.",
        accessor: "GSTNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Jobber Code",
        accessor: "jobberCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setJobberId(cellProps.row.original._id)
                  setUser({
                    ...user,
                    userName: cellProps.row.original.userId?.userName,
                  })
                  toggle()
                }}
              >
                <i className="fas fa-user-alt font-size-18" id="modifyUser" />
                <UncontrolledTooltip placement="top" target="modifyUser">
                  Modify User
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const editId = cellProps.row.original._id
                  props.history.push("/jobber/edit/" + editId)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const jobberId = cellProps.row.original._id
                  setJobberId(jobberId)
                  setDeleteModal(true)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Jobber" breadcrumbItem="Jobber" />

        {/* Delete Modal */}
        <ConfirmationModal
          show={deleteModalModal}
          onClick={onClickDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleteLoading}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={jobber}
                  isSearchable={true}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  addButtonLabel={"Add Jobber"}
                  handleAddButtonClick={handleAddButtonClick}
                  dataFetchLoading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Modal toggle={toggle} isOpen={modal}>
          <ModalHeader toggle={toggle}>User Modify</ModalHeader>
          <ModalBody>
            <form onSubmit={handleUpdateJobber}>
              <Row>
                <div>
                  <Label>
                    User Name
                    <span className="text-danger">*</span>
                  </Label>

                  <InputField
                    type="text"
                    name="userName"
                    placeholder="Enter User Name"
                    onChange={handleChange}
                    value={user.userName || ""}
                  />
                </div>
              </Row>
              <Row className="my-2">
                <div>
                  <Label>
                    Password
                    <span className="text-danger">*</span>
                  </Label>

                  <InputField
                    type="password"
                    name="password"
                    placeholder="Enter password"
                    onChange={handleChange}
                    value={user.password || ""}
                  />
                </div>
              </Row>
              <div className="d-flex justify-content-center gap-3">
                <Button color="danger" className="mt-2">
                  Cancel
                </Button>
                <Button color="blue" type="submit" className="mt-2">
                  Update
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  )
}

export default JobberList
