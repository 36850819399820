import React from "react"
import { InputGroup } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

function DatePicker({ value, onChange, onBlur, name }) {
  return (
    <div>
      <div className="docs-datepicker">
        <InputGroup>
          <Flatpickr
            value={value}
            className="form-control d-block bg-white"
            placeholder="DD/MM/YYYY"
            name={name}
            options={{
              dateFormat: "d/m/Y",
              onBlur: onBlur,
            }}
            // onBlur={validation.handleBlur}
            onChange={onChange}
          />
          {/* <div className="input-group-append">
                        <button type="button" className="btn btn-outline-secondary docs-datepicker-trigger" disabled>
                            <i className="fa fa-calendar" aria-hidden="true" />
                        </button>
                    </div> */}
        </InputGroup>
        <div className="docs-datepicker-container" />
      </div>
    </div>
  )
}

export default DatePicker
