import { getApiConfig } from "../authHelper"

import { get, post, put, del } from "../api_helper"
import * as url from "../url_helper"

// Fetch SubCompany
export const getAllSubCompanies = () => {
  return get(url.SUB_COMPANY, getApiConfig())
}

// Create SubCompany
export const createSubCompanyRecord = data => {
  return post(url.SUB_COMPANY, data, getApiConfig())
}

// Delete SubCompany
export const deleteSubCompanyRecord = id => {
  return del(`${url.SUB_COMPANY}/${id}`, getApiConfig())
}

// Fetch Edited SubCompany
export const fetchEditedSubCompany = id => {
  return get(`${url.SUB_COMPANY}?_id=${id}`, getApiConfig())
}

export const updateSubCompanyRecord = (id, data) => {
  return put(`${url.SUB_COMPANY}/${id}`, data, getApiConfig())
}
