import React from "react"
import Breadcrumb from "components/Common/Breadcrumb"
import TableContainer from "components/Common/TableContainer"
import { useEffect } from "react"
import { useMemo } from "react"
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap"
import { useState } from "react"
import { FormatDate, SimpleStringValue } from "helpers/common_helper_functions"
import { Button } from "reactstrap"
import {
  deleteManufactureRecord,
  fetchManufactureData,
} from "helpers/backendHelpers/manufacture"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import ConfirmationModal from "components/Common/ConfirmationModal"
import { SaveToast } from "components/Common/SaveToast"

const ManufactureList = props => {
  //meta title
  document.title = "Manufacturer | VH BROTHER"

  const [fetchUsers, setFetchUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const [deleteId, setDeleteId] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const fetchManufactureRecords = async () => {
    setLoading(true)
    try {
      const response = await fetchManufactureData()
      const result = response.payload
      setLoading(false)
      setFetchUsers(result.data)
    } catch (error) {
      setLoading(false)
      return error
    }
  }
  useEffect(() => {
    fetchManufactureRecords()
  }, [])

  const deleteManufacture = async () => {
    setDeleteLoading(true)
    try {
      const response = await deleteManufactureRecord(deleteId)
      SaveToast({ type: "success", message: response.message })
      fetchManufactureRecords()
      setDeleteModal(false)
      setDeleteLoading(false)
    } catch (error) {
      setDeleteLoading(false)
      return error
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Manufacture No",
        accessor: "manufactureNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "GST No",
        accessor: "GSTNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Party Name",
        accessor: "partyName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Address",
        accessor: "address",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Date Of Joining",
        accessor: "dateOfJoining",
        filterable: true,
        Cell: cellProps => {
          return <FormatDate {...cellProps} />
        },
      },
      {
        Header: "Mobile No",
        accessor: "mobileNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Party Code",
        accessor: "manufactureCode",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const editId = cellProps.row.original._id
                  props.history.push("/manufacturer/edit/" + editId)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const deleteId = cellProps.row.original._id
                  setDeleteId(deleteId)
                  setDeleteModal(true)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb title="Manufacturer" breadcrumbItem="Manufacturer" />
        </div>

        {/* Delete Modal */}
        <ConfirmationModal
          show={deleteModal}
          onClick={deleteManufacture}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleteLoading}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={fetchUsers}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  addButtonLabel={"Add Manufacturer"}
                  addButtonLink={"/manufacturer/add"}
                  dataFetchLoading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ManufactureList)

ManufactureList.propTypes = {
  history: PropTypes.object,
}
