import Breadcrumb from "components/Common/Breadcrumb"
import { SaveToast } from "components/Common/SaveToast"
import TableContainer from "components/Common/TableContainer"
import {
  deleteSubCompanyRecord,
  getAllSubCompanies,
} from "helpers/backendHelpers/sub_company"
import { SimpleStringValue } from "helpers/common_helper_functions"
import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import ConfirmationModal from "components/Common/ConfirmationModal"

const SubCompanyList = props => {
  //meta title
  document.title = "Sub Company | VH BROTHER"

  const [subCompany, setSubCompany] = useState([])
  const [loading, setLoading] = useState(false)

  const [subCompanyId, setSubCompanyId] = useState("")
  const [deleteModalModal, setDeleteModal] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  useEffect(() => {
    fetchSubCompanies()
  }, [])

  // fetch all subCompany
  const fetchSubCompanies = async () => {
    setLoading(true)
    try {
      const response = await getAllSubCompanies()
      setSubCompany(response.payload.data)
      setLoading(false)
    } catch (error) {
      SaveToast({ type: "error", message: error.response.data.message })
      setLoading(false)
    }
  }

  // add button click
  const handleAddButtonClick = () => {
    props.history.push("/subCompany/add")
  }
  const onClickDelete = async () => {
    setDeleteLoading(true)
    try {
      const response = await deleteSubCompanyRecord(subCompanyId)
      SaveToast({ type: "success", message: response.message })
      fetchSubCompanies()
      setDeleteModal(false)
      setDeleteLoading(false)
    } catch (error) {
      setDeleteLoading(false)
      return error
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "GST No",
        accessor: "GSTNo",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-blue"
                onClick={() => {
                  setSubCompanyId(cellProps.row.original._id)
                }}
              >
                <i className="fas fa-user-alt font-size-18" id="modifyUser" />
                <UncontrolledTooltip placement="top" target="modifyUser">
                  Modify User
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const editId = cellProps.row.original._id
                  props.history.push("/subCompany/edit/" + editId)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const subCompanyId = cellProps.row.original._id
                  setSubCompanyId(subCompanyId)
                  setDeleteModal(true)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Sub Company" breadcrumbItem="Sub Company" />

        {/* Delete Modal */}
        <ConfirmationModal
          show={deleteModalModal}
          onClick={onClickDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleteLoading}
        />
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={subCompany}
                  isSearchable={true}
                  isGlobalFilter={true}
                  isAddOptions={true}
                  addButtonLabel={"Add Sub Company"}
                  handleAddButtonClick={handleAddButtonClick}
                  dataFetchLoading={loading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SubCompanyList
